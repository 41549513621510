<template>
  <header class="main-header_auc main-header_auc--login">
    <div class="main-header_auc-item">
    <SocialsList/>
      <a href="https://crypto-graveyard.io/" class="main-header_meta-box-link">
        <img src="@/assets/img/logo.png" alt="graveyard" />
      </a>
    </div>
    <a href="/12apes" class="main-header_auc-logo main-header_auc-logo--login">
      <img src="@/assets/img/12meta.png" alt="12" />
    </a>
    <LoginCondition/>
  </header>
</template>
<script>
import SocialsList from "@/components/ui/SocialsList.vue";
import LoginCondition from "@/components/parts/auction/LoginCondition.vue";
export default {
  name: "AucHeader",
  components: {
    SocialsList,
    LoginCondition
  },
  
  
}
</script>


<style lang="scss">


</style>