<template>
    <div class="modal-wrap">
        <slot @closeModal="closeModal" />
        <div @click.stop="closeModal(false)" class="modal-close"></div>
    </div>
</template>

<script>

export default ({
    methods:{
        closeModal(status){
            this.$emit('closeModal', status)
        }
    }
})
</script>

<style scoped lang="scss">
    .modal-wrap{
        width: 100vw;
        height: 100vh;
        overflow: auto;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .7);
    }

    .modal-close{
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
</style>
