<template>
  <header class="main-header_auc-mobile">
    <div class="main-header_auc-mobile_wrap">
      <a href="/" class="main-header_auc-mobile-logo">
        <img src="@/assets/img/logo.png" alt="crypto-graveyard" />
      </a>
      <a href="/12apes" class="main-header_auc-mobile-logo">
        <img src="@/assets/img/12meta.png" alt="12" />
      </a>
    </div>
    <button class="button main-header_auc-mobile-button" :class="getStatusMobileMenu == 'open' ? 'open' : ''" @click="openMenu">
      menu
    </button>
    <MobileMenu/>
  </header>
</template>
<script>
import MobileMenu from "@/components/parts/auction/MobileMenu.vue";
export default {
  name: "AucHeader",
  components: {
    MobileMenu,
  },
  computed: {
    getStatusMobileMenu() {
      return this.$store.getters.getStatusMobileMenu
    }
  },
  methods: {
    openMenu() {
      if (this.getStatusMobileMenu == 'open') {
        this.$store.commit("setStatusMobileMenu", "")
      } else {
        this.$store.commit("setStatusMobileMenu", "open")
      }
     
    }
  }
}
</script>

<style lang="scss">
.main-header_auc-mobile_wrap {
  display: flex;
  align-items: center;
  gap: 1rem
}


.main-header_auc-mobile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: $black;
    padding: .75rem;
    position: sticky;
    top: 0;
    z-index: 9;
}
.main-header_auc-mobile-logo {
    width: 5.5rem;
    height: 3.5rem;
    position: relative;
    z-index: 4;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.main-header_auc-mobile-button {
    font-size: 1.5rem;
    border: 4px solid $dark;
    border-radius: 10px;
    position: relative;
    z-index: 4;
    &::after,
    &::before {
      background-color: #fff;
      content: "";
      height: 2px;
      opacity: 0;
      position: absolute;
      right: 0.2rem;
      top: calc(50% - 1px);
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      width: 1.2rem;
    }
    &.open {
      background-color: #f969e6;
      color: #fff;
      padding-right: 1.5rem;
      transform: rotate(0deg) scale(1.07);
      &::before {
        opacity: 1;
        transform: scale(1) rotate(45deg);
      }
      &::after {
        opacity: 1;
        transform: scale(1) rotate(-45deg);
      }
    }
}
</style>

