<template>
  <div class="colection-block">
    <h2 class="colection-block_title">12 Apes NFT collection</h2>
    <TableLoop v-if="getWebVersion !== 'mobile'" @openBidModal="openBidModal" @pushToSinglePage="pushToSinglePage"/>
    <TableMobileLoop
      v-if="getWebVersion == 'mobile'"
      @openBidModal="openBidModal"
      @pushToSinglePage="pushToSinglePage"
    />
  </div>
</template>

<script>
import TableMobileLoop from "@/components/parts/auction/image-loop/TableLoopMobile.vue";
import TableLoop from "@/components/parts/auction/image-loop/TableLoop.vue";
export default {
  name: "ColectionBlock",
  components: {
    TableLoop,
    TableMobileLoop,
  },
  computed: {
    getWebVersion() {
      return this.$store.getters.getWebVersion;
    },
  },
  methods: {
    openBidModal(id) {
      this.$emit("openBidModal", id);
    },
    pushToSinglePage(id) {
      this.$emit("pushToSinglePage", id);
    },
  },
};
</script>

<style lang="scss">
.colection-block_title {
  font-size: 3rem;
  line-height: 3rem;
  color: $white;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 0.75rem;
  }
}
.colection-block {
  padding: 2rem 3.5rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 0rem;
  }
}
</style>