<template>
  <div class="nft-hello">
    <div class="nft-hello_image_wrap">
      <img :width="getWebVersion == 'mobile' ? 414 : 658" :height="getWebVersion == 'mobile' ? 414 : 658" class="nft-hello_image" :src="currentNFT.pathOriginal" ref="mainImage" :alt="currentNFT.name"/>
      <button @click="setFullImageModal" class="nft-hello_button"></button>
    </div>
    <button
      @click="changeNft(this.siblingElems.next.id, 'next')"
      class="nft-hello_image-next"
      v-if="
        this.siblingElems.next && getWebVersion !== 'mobile'
          ? this.siblingElems.next
          : ''
      "
    >
      <img :src="this.siblingElems.next ? nextEl.path : ''" />
      <div class="nft-hello_image-next-desc">
        <p class="title-nft">{{ this.siblingElems.next.name }}</p>
        <p class="price-nft">{{ this.siblingElems.next.price }} ETH</p>
      </div>
    </button>
    <button
      @click="changeNft(this.siblingElems.prev.id, 'prev')"
      class="nft-hello_image-previous"
      v-if="
        this.siblingElems.prev && getWebVersion !== 'mobile'
          ? this.siblingElems.prev
          : ''
      "
    >
      <img :src="this.siblingElems.prev ? prevEl.path : ''" />
      <div class="nft-hello_image-prev-desc">
        <p class="title-nft">{{ this.siblingElems.prev.name }}</p>
        <p class="price-nft">{{ this.siblingElems.prev.price }} ETH</p>
      </div>
    </button>
    
    <FullImage :currentNFT="currentNFT" />
  </div>
</template>
<script>
import FullImage from "@/components/modals/auction/FullImage.vue";
export default {
  name: "NftHello",
  components: {
    FullImage,
  },
  computed: {
    getWebVersion() {
      return this.$store.getters.getWebVersion;
    },
    nextEl() {
      let elem;
      if (this.siblingElems.next) {
        elem = this.siblingElems.next;
      }
      return elem;
    },
    prevEl() {
      let elem;
      if (this.siblingElems.prev) {
        elem = this.siblingElems.prev;
      }
      return elem;
    },
  },
  props: {
    currentNFT: Object,
    siblingElems: Object,
  },
  methods: {
    setFullImageModal() {
      this.$store.commit("setFullImageModal", "open");
      document.querySelector('body').style.overflow = "hidden";
    },
    changeNft(id, obj) {
      this.$refs.mainImage.classList.add("active");
      this.$router.push({
        path: `/12apes/${this.siblingElems[obj].id}`,
      });

      setTimeout(() => {
        this.$refs.mainImage.classList.remove("active");
        this.$store.commit("setCurrentPath", this.$route.path);
      }, 1000);
      this.$emit("changeNft", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.nft-hello {
  min-height: 100vh;
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &.overflow {
    overflow: hidden;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    min-height: auto;
    // position: static;
  }
}
.nft-hello_image-next-desc,
.nft-hello_image-prev-desc {
  background-color: #f8f8f8;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  text-align: start;
  .price-nft {
    margin-top: 0.25rem;
  }
}

.nft-hello_image_wrap {
  max-width: 48rem;
  max-height: 50rem;
  margin-top: 5rem;
  position: relative;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-top: 0;
  }
}

.nft-hello_image {
  width: 100%;
  height: 100%;

  position: relative;
  z-index: 2;
  margin-bottom: -6px;
  img {
    width: 100%;
    height: 100%;
  }
}

.nft-hello_button {
  background: url(@/assets/img/icons/fullimg.svg) center center no-repeat;
  width: 4rem;
  height: 4rem;
  position: absolute;
  bottom: 0;
  right: 0rem;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.nft-hello_image-next,
.nft-hello_image-previous {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 11rem;
  height: 11rem;
  cursor: pointer;
  color: $black;
  text-decoration: none;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: -4px;
  }
}
.nft-hello_image-previous {
  left: 0;
}

.nft-hello_image-next {
  right: 0;
}
.nft-hello_image {
  @media only screen and (min-width: 1200px) {
    transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
    // opacity: 1;
    // transform: scale(0.95) translateY(1rem);
    &.active {
      // opacity: 1;
      // transform: scale(1) translateY(0);
      animation: imageHello 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
}

@keyframes imageHello {
  0% {
    opacity: 1;
    transform: scale(0.95) translateY(1rem);
  }
  100% {
     transform: scale(1) translateY(0);
    opacity: 1;
  }
}
</style>