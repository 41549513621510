<template>
  <div :class="transparent ? 'transparent' : ''" class="loader" data-loader>
    <div class="loader__wrap">
      <div v-show="!transparent" class="loader__image">
        <img src="@/assets/img/logo.svg" alt="CRYPTO GRAVEYARD" />
      </div>
      <div :class="isMain ? 'has-tooltip' : ''" class="loader__content">
        <div class="loader__progress progress">
          <div class="progress__counter">0%</div>
          <div class="progress__outer">
            <span class="progress__inner"></span>
          </div>
        </div>
        <div v-show="!transparent" class="loader__text">
          <div v-if="isMain" class="grab-tooltip">
            <img src="@/assets/img/grab_tooltip.gif">
          </div>
          <div data-enter="" class="button button--large button--secondary">
            enter the graveyard!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            step: 0.25,
            total: 0,
            complete: false
        }
    },

    props: [
      'transparent',
      'isMain'
    ],

    watch: {
        total(){
            this.complete = this.$store.getters.getPageStatus
            this.step = this.complete ? 1 : this.step
            // console.log(this.complete, this.step)
        }
    },
  mounted() {
    const loader = document.querySelector("[data-loader]");

    const initLoader = (loader) => {
      if (!loader) return;
      //let total = 0;
      let time = 0;
      const count = loader.querySelector(".progress__counter");
      const bar = loader.querySelector(".progress__inner");
      const progress = loader.querySelector(".progress");
      const text = loader.querySelector(".loader__text");
      const button = loader.querySelector("[data-enter]");
      const endCallback = () => {
        setTimeout(() => {
          progress.style.opacity = 0;
          setTimeout(() => {
            text.style.display = "flex";
            // text.style.pointerEvents = "all";
            button.addEventListener("click", (e) => {
              e.preventDefault();
              loader.classList.add("hide");
              this.$store.commit('setCanvasStatus')
              setTimeout(() => {
                loader.remove();
              }, 350);
            });
          }, 200);
        }, 300);
      };

      const loading = () => {
        if (this.total >= 100) {
          endCallback();
        } else {
          if (time % 3 === 0) this.total += this.step;
          count.textContent = this.total > 100 ? '100%' : `${this.total.toFixed(0)}%`;
          bar.style.transform = `translateX(${this.total}%)`;
          requestAnimationFrame(loading);
        }
      };
      loading();
    };

    initLoader(loader);
  },
};
</script>

<style lang="scss" scoped>
.loader {
  background-color: $preloaderBg;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 51;
  display: flex;
  justify-content: center;
  align-items: center;

  &.transparent{
    background-color: transparent;
  }
}
.loader__wrap {
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.loader__image {
  width: 45rem;
  height: 17rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    width: 25rem;
    height: 10rem;
  }
}
.loader__content {
  position: relative;
  display: flex;
  justify-content: center;

  &.has-tooltip{
    min-height: 20rem;
  }
}
.loader__progress {
  width: 30rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    width: 20rem;
  }
}
.progress__outer {
  background: linear-gradient(90deg, #c4dbe8, #f969e6);
  border-radius: 0.4rem;
  height: 3rem;
  overflow: hidden;
  position: relative;
  //   transform: translateX(-100%);
}
.progress {
  padding: 3rem 1rem 1rem;
  transition: 0.2s cubic-bezier(0.55, 0, 1, 0.45);
}
.progress__counter {
  color: $white;
  margin-bottom: 0.5em;
  text-align: center;
  font-size: 2rem;
}
.progress__inner {
  background-color: #6e6175;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.grab-tooltip{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: -3rem;

  img{
    height: 100%;
    max-height: 12rem;
    width: auto;
  }
}
.loader__text {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: center;
  left: 0;
  display: none;
  padding-top: 2rem;
  pointer-events: all;
  position: absolute;
  top: 0;
  transition: 0.2s cubic-bezier(0.55, 0, 1, 0.45);
  width: 100%;
  .button {
    font-size: 4rem;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    .button {
        font-size: 2rem;
    }
  }
}
</style>

