export default {
    state: {
        // NFT: [
        //     {
        //         pathOriginal: require("@/assets/img/auc-nft/nft1.png"),
        //         path:  require("@/assets/img/auc-nft/image.png"),
        //         name: "Monkey #5679",
        //         price: 1.5,
        //         id: 1,
        //         end_time: 0,
        //         step: 0
        //     },
        //     {
        //         pathOriginal: require("@/assets/img/auc-nft/nft1.png"),
        //         path:  require("@/assets/img/auc-nft/image.png"),
        //         name: "Monkey #8798",
        //         price: 2,
        //         id: 2,
        //         end_time: 0,
        //         step: 0
        //     },
        //     {
        //         pathOriginal: require("@/assets/img/auc-nft/nft3.png"),
        //         path:  require("@/assets/img/auc-nft/image3.png"),
        //         name: "Monkey #3242",
        //         price: 1.8,
        //         id: 3,
        //         end_time: 0,
        //         step: 0,
                
        //     },
        //     {
        //         pathOriginal: require("@/assets/img/auc-nft/nft4.png"),
        //         path:  require("@/assets/img/auc-nft/image4.png"),
        //         name: "Monkey #3456",
        //         price: 1.9,
        //         id: 4,
        //         end_time: 0,
        //         step: 0
        //     },
        // ],
        loopStatus: "grid",
        fullImageModal: "",
        modalStatus: "",
        networkConection: "good",
        // maxUserBid: 0,
        balance: 6,
        valletConected: false,
        signatureModalStatus: "",
        statusMobileMenu: "",
        shareModalStatus: "",
        currentPath: "",
        userAvatar: {
            colors: [
                "#FFE000",
                "#799F0C"
            ]
        },
        bidStatus: false,
    },
    getters: {
        getLoopStatus(state) {
            return state.loopStatus
        },
        getFullImageModal(state) {
            return state.fullImageModal
        },
        getModalStatus(state) {
            return state.modalStatus
        },
        getNetworkConection(state) {
            return state.networkConection
        },
        getMaxUserBid(state) {
            return state.maxUserBid
        },
        getBalance(state) {
            return state.balance
        },
        getValletConected(state) {
            return state.valletConected
        },
        getSignatureModalStatus(state) {
            return state.signatureModalStatus
        },
        getStatusMobileMenu(state) {
            return state.statusMobileMenu
        },
        getShareModalStatus(state) {
            return state.shareModalStatus
        },
        getCurrentPath(state) {
            return state.currentPath
        },
        getUserAvatar(state) {
            return state.userAvatar
        },
        // getEndTime(state) {
        //     return state.NFT.end_time
        // },
        // getStep(state) {
        //     return state.NFT.step
        // }
        getBidStatus(state) {
            return state.bidStatus
        },

    },
    mutations: {
        setLoopStatus(state, name) {
            state.loopStatus = name
        },
        setFullImageModal(state, newStatus) {
            state.fullImageModal = newStatus
        },
        setModalStatus(state, newStatus) {
            state.modalStatus = newStatus
        },
        // setMaxUserBid(state, newBid) {
        //     state.maxUserBid = newBid
        // },
        setSignatureModalStatus(state, newStatus) {
            state.signatureModalStatus = newStatus
        },
        setValletConected(state, newStatus) {
            state.valletConected = newStatus
        },
        setStatusMobileMenu(state, newStatus) {
            state.statusMobileMenu = newStatus
        },
        setShareModalStatus(state, newStatus) {
            state.shareModalStatus = newStatus
        },
        setCurrentPath(state, newPath) {
            state.currentPath = newPath
        },
        setUserAvatar(state, icon) {
            state.userAvatar = {...icon}
        },
        setBidStatus(state, status) {
            state.bidStatus = status
        },
    }
}