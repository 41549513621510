<template>
    <ModalHead 
        @closeModal="closeModal"
    >
        <h2>Payment settings</h2>
    </ModalHead>
    <div class="content-wrapper row">
        <div class="row">
            <InputNumber
                :value="props.payment_mint_quantity"
                name="payment_mint_quantity"
                @setValue="setValue"
            >
                Mint quantity
            </InputNumber>

            <ButtonUi
                buttonClass="green"
                style="margin-left: 1rem;"
                :disabled="setValue"
                @click="sendData"
                data-changed
            >Update</ButtonUi>
        </div>
        <div class="row">
            <InputNumber
                :value="props.payment_public_mint / Math.pow(10, 18)"
                staticValue="ETH"
                name="payment_public_mint"
                @setValue="setValue"
            >
                Public mint price
            </InputNumber>

            <ButtonUi
                buttonClass="green"
                style="margin-left: 1rem;"
                :disabled="setValue"
                @click="sendData"
                data-changed
            >Update</ButtonUi>
        </div>
        <div class="row">
            <InputNumber
                :value="props.payment_waitlist / Math.pow(10, 18)"
                staticValue="ETH"
                name="payment_waitlist"
                @setValue="setValue"
            >
                Waitlist price
            </InputNumber>

            <ButtonUi
                buttonClass="green"
                style="margin-left: 1rem;"
                :disabled="setValue"
                @click="sendData"
                data-changed
            >Update</ButtonUi>
        </div>
        <!-- <div class="row">
            <InputNumber
                :value="props.payment_royalty"
                staticValue="%"
                name="payment_royalty"
                @setValue="setValue"
            >
                Royalty
            </InputNumber>

            <ButtonUi
                buttonClass="green"
                style="margin-left: 1rem;"
                :disabled="setValue"
                @click="sendData"
                data-changed
            >Update</ButtonUi>
        </div> -->
    </div>

    <div class="content-wrapper">
        <div class="row">
            <InputGroup 
                label="Status"
            >
                <RadioButton
                    label="WaitList"
                    value="wait"
                    name="payment_status"
                    v-model="value"
                    :checked="props.payment_status == 'whitelist'"
                    @setValue="setValue"
                />
                <RadioButton
                    label="Public Mint"
                    value="public"
                    v-model="value"
                    name="payment_status"
                    :checked="props.payment_status == 'public'"
                    @setValue="setValue"
                />

                <ButtonUi
                    buttonClass="green"
                    style="margin-left: 1rem;"
                    :disabled="setValue"
                    @click="sendData"
                    data-changed
                >Update</ButtonUi>
            </InputGroup>
        </div>
    </div>
</template>

<script>
import ModalHead from '@/components/modals/project/ModalHead.vue'
import InputNumber from '@/components/ui/InputNumber.vue'
import ButtonUi from '@/components/ui/ButtonUi.vue'
import InputGroup from '@/components/ui/InputGroup.vue'
import RadioButton from '@/components/ui/RadioButton.vue'

export default ({
    emits: ["closeModal", "sendData"],
    data() {
        return {
            totalPercent: 100,
            value: '',
        }
    },
    // props: [
    //     'props'
    // ],
    components: {
        ModalHead,
        ButtonUi,
        InputNumber,
        InputGroup,
        RadioButton
    },

    computed: {
        wallets() {
            return this.$store.getters.getWallets
        },

        props() {
            let obj = {
                "payment_mint_quantity": this.$store.getters.getCurProjectContract.maxCountMinting,
                "payment_public_mint" : this.$store.getters.getCurProjectContract.baseCost,
                "payment_waitlist" : this.$store.getters.getCurProjectContract.whitelistCost,
                "payment_status" : this.$store.getters.getCurProjectContract.projectStatus
            }

            return obj
        }
    },

    methods:{
        closeModal(status){
            this.$emit('closeModal', status)
        },

        setValue(evt, value, name) {
            let button = evt.target.closest('.row').querySelector('.button-admin')
            let data = {}
            data[name] = value
            this.props[name] == value ? button.setAttribute('disabled', 'disabled') : button.removeAttribute('disabled', 'disabled')
            button.dataset.changed = JSON.stringify(data)
            return this.props[name] == value
        },

        sendData(evt) {
            let dataObj = JSON.parse(evt.target.dataset.changed)

            if(dataObj.payment_mint_quantity){
                this.$store.getters.getCurProjectContract.setMaxCountMinting(dataObj.payment_mint_quantity)
            }

            if(dataObj.payment_public_mint){
                this.$store.getters.getCurProjectContract.setBaseCost(parseFloat(dataObj.payment_public_mint))
            }

            if(dataObj.payment_waitlist){
                this.$store.getters.getCurProjectContract.setWhitelistCost(parseFloat(dataObj.payment_waitlist))
            }

            if(dataObj.payment_status){
                this.$store.getters.getCurProjectContract.setWhitelistStatus(dataObj.payment_status == "wait")
            }

            let data = {
                "acf": dataObj
            }
            this.$store.commit('setCurrentBtn', evt.target)
            this.$emit('sendData', data)
        },
    },
})
</script>

<style scoped lang="scss">
    h2{
        font: inherit;
    }

    .content-wrapper{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1.25rem;
        padding: 1.75rem 0;
        border-bottom: 1px solid $darkBlue;
        overflow: hidden;

        &.row{
            display: flex;
            justify-content: flex-start;
            grid-gap: 0;
            & > *{
                margin-right: 1.75rem;
                width: max-content;
            }
        }

        &.scroll-content{
            max-height: 30vh;
            overflow: scroll;
            border-bottom: none;
            padding-top: 0;
            padding-bottom: 1rem;
            width: 100%;
            margin-top: -.5rem;

            &::-webkit-scrollbar {
                display:none;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
            }
        }
    }

    .bottom-buttons{
        margin-top: -.25rem;
        position: relative;
        box-shadow: 0 -1rem 2rem 1rem $black;
    }

    .buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1.75rem;
    }

    .wallet-row{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        & > *:not(:last-child){
            margin-right: 1.75rem;
            width: max-content;
        }

        label:first-child{
            flex-grow: 1;
            max-width: 38.75rem;
        }
    }

    .row{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
</style>

