<template>
    <div>
        <AdminHeader>
            <h1>Your Projects</h1>
        </AdminHeader>
        <AllProjects 
            @openModal="modalStatus = !modalStatus"
        />
        <AdminModal
            v-if="modalStatus"
            @closeModal="modalStatus = !modalStatus"
        >
            <AddProject />
        </AdminModal>
    </div>
</template>

<script>
import AdminHeader from '@/components/headers/AdminHeader.vue'
import AllProjects from '@/components/AllProjects.vue'
import AdminModal from '@/components/modals/AdminModal.vue'
import AddProject from '@/components/parts/AddProject.vue'

export default ({
    name: "ProjectsPage",
    data() {
        return {
            modalStatus: false
        }
    },

    components: {
        AdminHeader,
        AllProjects,
        AdminModal,
        AddProject
    },
})

</script>

<style lang="scss" scoped>
    h1{
        color: $white;
        font-size: 3rem;
        line-height: 3.5rem; 
        font-weight: 400;
    }
</style>
