export default {
    state: {
        mittingObj: {
            status: 'whitelist',
        },
        modal: 'close',
    },
    getters: {
        getMintingStatus(state) {
            return state.mittingObj.status
        },
        getStatusModal(state) {
            return state.modal
        }
    },
    mutations: {
        updateStatusModal(state, newStatus) {
            state.modal = newStatus
        }
    }
}