<template>
    <div class="waiting-payment_status-error">
      <p class="waiting-payment_text">
        Waiting for your payment to be finished. Usually it takes few minutes.
        If you’ll accidently close this window, you will find your token in your
        <a :href="openseaAccountUrl" target="_blank">OpenSea account</a>.
      </p>
      <p class="waiting-payment_text">Status</p>
      <p class="waiting-payment_text-status waiting-payment_text-status--error"><span>Failed</span><span class="failed"></span></p>
      <a :href="`${polygonUrl}tx/${transaction}`" target="_blank" v-if="transaction">View in blockchain</a>
      <p class="waiting-payment_text" v-if="transaction">Transaction ID:</p>
      <p class="waiting-payment_text-status" v-if="transaction">
        <span>{{ transaction }}</span>
        <button @click="copyText">(Copy)</button>
      </p>
    </div>
</template>
<script>
import { copyFunction } from "@/components/common/copy-function.js";
export default {
    name: "PaymentErrorStatus",
    data() {
        return {
            copyFunction,
            openseaAccountUrl: "",
            polygonUrl: ""
        }
        
    },
    props: {
        transaction: String,
    },
    methods: {
        copyText(event) {
            copyFunction(event, this.transaction)
        }
    },
    mounted(){
        this.openseaAccountUrl = process.env.VUE_APP_OPENSEA_ACCOUNT
        this.polygonUrl = process.env.VUE_APP_POLYGON_URL
    }
}
</script>