<template>
  <AppLayoutHeader />
  <div class="wrap-404">
    <h1 class="wrap-404_title">404</h1>
    <div class="wrap-404_content">
      <p>The page you’re looking for can’t be found.</p>
    </div>
    <a href="/" target="" class="button button--secondary">Back to the graveyard</a>
  </div>
</template>

<script>
import AppLayoutHeader from "@/layouts/AppLayoutHeader.vue";
export default {
  components: {
    AppLayoutHeader,
  },
  mounted() {
    //проверка на мобилку
    if (innerWidth < 1200) {
      this.$store.commit("setWebVersion", 'mobile')
    }
  },
};
</script>

<style lang="scss">
.wrap-404 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 1rem;
  background-color: #12090f;
  text-align: center;
  .button {
      font-size: 2rem
  }
}

.wrap-404_title {
  @include h1;
  color: #f969e6;
  font-size: 15rem;
  z-index: 2;
}

.wrap-404_content {
    color: #c4dbe8;
    padding: 1rem 0 2rem;
   font-size: 2rem;
}
</style>