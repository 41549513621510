import Wallet from "@/helpers/Wallet.js"
import Contract from "@/helpers/Contract.js"

export default {
    state: {
        wallet: null,
        metamaskIstalled: false,
        contract: null,
        mainChainId: process.env.VUE_APP_CHAIN_ID,
    },
    getters: {
        getWallet(state) {
            return state.wallet
        },
        getContract(state) {
            return state.contract
        },
        getMetamaskInstalled(state) {
            return state.metamaskIstalled
        },
        getMainChainId(state) {
            return state.mainChainId
        },
        getContractAbi(state) {
            return state.contractAbi
        }
    },
    mutations: {
        createWallet(state) {
            state.wallet = new Wallet();
            state.wallet.metamaskEnabled(this);
            state.wallet.checkConnect(this);
        },
        createContract(state) {
            state.contract = new Contract();
            state.contract.init(process.env.VUE_APP_CONTRACT_ADDRESS, this);
        },
        setMetamaskInstalled(state, metamaskIstalled){
            state.metamaskIstalled = metamaskIstalled;
        },
        async setUserAddress(state){
            await state.contract.getData();
        }
    }
}