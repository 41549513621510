<template>
    <label>
        <slot />
        <textarea rows="5" :name="name" :placeholder="placeholder" :value="value"></textarea>
    </label>
</template>

<script>

export default ({
   props: [
       'placeholder',
       'value',
       'name'
   ]
})
</script>

<style scoped lang="scss">
    label {
        font-size: .875rem;
        line-height: 1rem;
        color: $white;
        width: 100%;

        textarea{
            display: block;
            margin-top: .5rem;
            width: 100%;
            border: 1px solid $gray3;
            border-radius: .25rem;
            background-color: $darkBlue;
            padding: .625rem;
            color: $white;
            font-size: 1.185rem;
            line-height: 1.35rem;
            font-weight: 500;
            font-family: inherit;
            resize: none;
            transition: .1s ease-out;

            &:hover{
                border-color: $white;
            }

            &::placeholder{
                color: $gray3;
            }

            &:focus{
                outline: none;
                background-color: $black;
                border-color: $gray3;
            }
        }
    }
</style>
