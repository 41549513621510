<template>
  <div class="grid-loop">
    <NftView
      v-for="item of arrayNft"
      :key="item.id + Math.random()"
      :name="item.name"
      :price="item.price"
      :path="item.path"
      :id="item.id"
      :endTime="item.end_time"
      @pushToSinglePage="pushToSinglePage"
    />
  </div>
</template>

<script>
import NftView from "@/components/parts/auction/NftView.vue";
export default {
  name: "GridLoop",
  components: {
    NftView,
  },
  computed: {
    arrayNft() {
      return this.$store.getters.getNft;
    },
  },
  methods: {
    pushToSinglePage(id) {
      this.$router.push({ path: `/12apes/${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-loop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  margin-top: 2rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;
  }
}
</style>