// @ts-nocheck
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import contract_abi from '@/assets/json/contract_abi.json'
import contract_abi_auction from '@/assets/json/contract_abi_auction.json'


export default class Contract{
  type = ""
  connector = null
  web3 = null
  wallet = null
  store = null
  SmartContractObj = null
  projectStatus = null
  userStatus = null
  defaultPrice = null
  mintedCount = null
  maxMintAmount = null
  maxValue = null
  mintedNftCount = null
  baseCost = null
  whitelistCost = null
  contract_address = null
  maxCountMinting = null
  baseURI = null
  contractAbi = null

  onError = function(){}
  onSetTransaction = function(){}
  onSuccess = function(){}
  onErrorBallance = function(){}

  constructor(type = "") {
    this.type = type

    if(type == "auction"){
      this.contractAbi = contract_abi_auction
    } else {
      this.contractAbi = contract_abi
    }
  }

  async init(contract_address, store) {
    this.contract_address = contract_address;
    this.store = store;

    await Web3EthContract.setProvider("https://mainnet.infura.io/v3/9c8d8cc65f4841e3822932c8797fd0ec");
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      contract_address
    );

    this.SmartContractObj = SmartContractObj

    window.web3 = new Web3(window.ethereum);
    this.web3 = window.web3
    this.connector = window.ethereum;

    await this.getData();
  }

  async getData(){
    if(this.type != "auction"){
      await this.getStatus();
      await this.getUserGroup();
      await this.getBaseCost();
      await this.getWhitelistCost();
      this.getDefaultPrice();
      this.getMintedCount();
      this.getMaxMintAmount();
      this.getMaxValue();
      this.getMintedNftCount();
      this.getMaxCountMinting();
      this.getBaseURI();
    }
  }

  async getPriceForAmount(count){
    let result = null
    await this.SmartContractObj.methods.getPriceForAmount(this.store.getters.getWallet.wallet.address, count).call().then((price) => {
      result = price / Math.pow(10, 18)
    });

    return result
  }

  async getBaseCost(){
    const $this = this;

    await this.SmartContractObj.methods.baseCost().call().then((baseCost) => {
      $this.baseCost = baseCost
    });
  }

  async getWhitelistCost(){
    const $this = this;

    await this.SmartContractObj.methods.whitelistCost().call().then((whitelistCost) => {
      $this.whitelistCost = whitelistCost
    });
  }

  async getMaxValue(){
    const $this = this;

    await this.SmartContractObj.methods.maxValue().call().then((count) => {
      $this.maxValue = count
    });
  }

  async getMintedCount(){
    if(this.store.getters.getWallet.wallet.address){
      const $this = this;

      await this.SmartContractObj.methods.mintedCount(this.store.getters.getWallet.wallet.address).call().then((count) => {
        $this.mintedCount = count
      });
    }
  }

  async getMintedNftCount(){
    const $this = this;

    await this.SmartContractObj.methods.mintedNftCount().call().then((count) => {
      $this.mintedNftCount = count
    });
  }

  async getMaxMintAmount(){
    if(this.store.getters.getWallet.wallet.address){
      const $this = this;

      await this.SmartContractObj.methods.getMaxMintAmount(this.store.getters.getWallet.wallet.address).call().then((count) => {
        $this.maxMintAmount = count
      });
    }
  }

  async getDefaultPrice(){
    if(this.projectStatus == "whitelist"){
      this.defaultPrice = this.whitelistCost / Math.pow(10, 18)
    } else {
      this.defaultPrice = this.baseCost / Math.pow(10, 18)
    }
  }

  async getMaxCountMinting(){
    const $this = this;

    await this.SmartContractObj.methods.maxCountMinting().call().then((maxCountMinting) => {
      $this.maxCountMinting = maxCountMinting
    });
  }

  async getStatus(){
    const $this = this;
    await this.SmartContractObj.methods.whitelistStatus().call().then((status) => {
      $this.projectStatus = status ? "whitelist" : "public"
    });
  }

  async getBaseURI(){
    const $this = this;
    await this.SmartContractObj.methods.baseURI().call().then((baseURI) => {
      $this.baseURI = baseURI
    });
  }

  async getUserGroup(){
    if(this.store.getters.getWallet.wallet.address){
      const $this = this;

      await this.SmartContractObj.methods.whitelisted(this.store.getters.getWallet.wallet.address).call().then((status) => {
        $this.userStatus = status ? "whitelist" : "public"
      });
    }
  }

  async getAuctions(count){
    const auctions = []
    for (var id = 0; id < count; id++) {
      const auction = await this.getAuction(id)
      auctions.push({
        "id": id,
        "bidcount": parseInt(auction.bidcount),
        "current_price": auction.current_price / Math.pow(10, 18),
        "end_time": parseInt(auction.end_time),
        "step": auction.step / Math.pow(10, 18),
        "ended": auction.ended
      })
    }

    return auctions
  }

  async getBidsHash(auction_id){
    let bidsHash = []
    await this.SmartContractObj.getPastEvents('BidEvent', {
      fromBlock: 0,
      toBlock: 'latest'
    })
    .then(function(events){
      events.forEach(function(item){
        if(parseInt(item.returnValues.auction_id) == auction_id){
          bidsHash.push(item)
        }
      });
    })
    .catch(function(e) { throw new Error(e) })

    return bidsHash;
  }

  async getBid(auction_id, bid_id){
    let result = null

    await this.SmartContractObj.methods.getBid(auction_id, bid_id).call().then((bidInfo) => {
      result = {
        "bid_price": bidInfo.bid_price / Math.pow(10, 18),
        "bidder": bidInfo.bidder
      }
    });

    return result
  }

  async getAuction(id){
    let result = null

    await this.SmartContractObj.methods.getAuction(id).call().then((auctionInfo) => {
      result = auctionInfo
    });

    return result
  }

  async setBaseCost(cost){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    SmartContractObj.methods.setBaseCost(this.web3.utils.toBN(cost * Math.pow(10, 18))).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async setWhitelistCost(cost){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    SmartContractObj.methods.setWhitelistCost(this.web3.utils.toBN(cost * Math.pow(10, 18))).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async setWhitelistStatus(status){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    SmartContractObj.methods.setWhitelistStatus(status).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async setMaxValue(value){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    SmartContractObj.methods.setMaxValue(value).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async setMaxCountMinting(count){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    SmartContractObj.methods.setMaxCountMinting(count).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async setBaseURI(uri){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    SmartContractObj.methods.setBaseURI(uri).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async addOwnersUsers(addresses, counts){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    let addressesBlockChain = [];
    let countsBlockChain = [];

    addresses.forEach(function(address, index){
      if($this.web3.utils.isAddress(address)){
        addressesBlockChain.push(address)
        countsBlockChain.push(counts[index])
      }
    });
    
    SmartContractObj.methods.addOwnersUsers(addressesBlockChain, countsBlockChain).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async addGiveawayUsers(addresses, counts){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    let addressesBlockChain = [];
    let countsBlockChain = [];

    addresses.forEach(function(address, index){
      if($this.web3.utils.isAddress(address)){
        addressesBlockChain.push(address)
        countsBlockChain.push(counts[index])
      }
    });
    
    SmartContractObj.methods.addGiveawayUsers(addressesBlockChain, countsBlockChain).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async addWhitelistedUsers(addresses){
    const $this = this;

    await Web3EthContract.setProvider(this.connector);
    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    let addressesBlockChain = [];

    addresses.forEach(function(address){
      if($this.web3.utils.isAddress(address)){
        addressesBlockChain.push(address)
      }
    });
    
    SmartContractObj.methods.addWhitelistedUsers(addressesBlockChain).send({ 
      from: $this.store.getters.getWallet.wallet.address 
    }).on('error', function(){
      // console.log(error)
    })
    .on('confirmation', function(){
      // console.log(confirmationNumber);
      // console.log(receipt);
    })
    .on('transactionHash', function(){
      // console.log(transactionHash);
    })
    .on('receipt', function(){
      $this.getData()
    })
  }

  async mint(count){
    const $this = this;

    const wallet = this.store.getters.getWallet


    const price = await this.getPriceForAmount(count)
    await Web3EthContract.setProvider(this.connector);

    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    const ballance = await this.store.getters.getWallet.getBallance();

    if(ballance < price * Math.pow(10, 18)){
      this.onErrorBallance()
    } else{
      SmartContractObj.methods
      .mint(wallet.wallet.address, count)
      .send({
        gasLimit: "600000",
        to: this.contract_address,
        from: wallet.wallet.address,
        value: price * Math.pow(10, 18),
      })
      .on('error', function(error){
        $this.onError(error);
      })
      .on('confirmation', function(){
        // console.log(confirmationNumber);
        // console.log(receipt);
      })
      .on('transactionHash', function(transactionHash){
        $this.onSetTransaction(transactionHash)
      })
      .on('receipt', function(){
        $this.onSuccess();
      })
    }
  }

  async bid(auction_id, price){
    const $this = this;

    const wallet = this.store.getters.getWallet

    await Web3EthContract.setProvider(this.connector);

    const SmartContractObj = await new Web3EthContract(
      this.contractAbi,
      this.contract_address
    );

    const ballance = await this.store.getters.getWallet.getBallance();

    if(ballance < price * Math.pow(10, 18)){
      this.onErrorBallance()
    } else{
      SmartContractObj.methods
      .bid(auction_id)
      .send({
        gasLimit: "3000000",
        to: this.contract_address,
        from: wallet.wallet.address,
        value: price * Math.pow(10, 18),
      })
      .on('error', function(error){
        $this.onError(error);
      })
      .on('confirmation', function(){
        // console.log(confirmationNumber);
        // console.log(receipt);
      })
      .on('transactionHash', function(transactionHash){
        $this.onSetTransaction(transactionHash)
      })
      .on('receipt', function(){
        $this.onSuccess();
        $this.store.commit("getAuctionsInfo");
        $this.store.commit("setBidStatus", false);
        // console.log(receipt)
      })
    }
  }
} 