<template>
    <div class="wrapper">
        <InputText
            name="contract_address"
            @setValue="setValue"
        >
            Add contract address
        </InputText>
        <ButtonUi
            ref="create_button"
            @click="createContract"
            data-changed
        >Add project</ButtonUi>
    </div>
</template>

<script>
import InputText from '@/components/ui/InputText.vue'
import ButtonUi from '@/components/ui/ButtonUi.vue'

export default({
    data() {
        return{
            address: ''
        }
    },
    components: {
        InputText,
        ButtonUi
    },

    methods: {
        setValue(evt, value, name){
            let button = evt.target.closest('.wrapper').querySelector('.button-admin')
            let data = {}
            data[name] = value
            button.dataset.changed = JSON.stringify(data)
        },

        createContract(evt) {
            let apiUrl = "https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/projects/"
            let data = JSON.parse(evt.target.dataset.changed)
            data['title'] = 'Untitled Project'
            data['ipfs_link'] = ''
            let dataReq = {
                "acf": data
            }
            let requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-WP-Nonce": localStorage.getItem("nonce"),
                },
                body: JSON.stringify({'status': 'publish'})
            };
      
            fetch(apiUrl, requestOptions)
                .then(response =>  response.json())
                .then((result) => {
                    let id = result.id
                    requestOptions['body'] = JSON.stringify(dataReq)
                    let url = "https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/projects/" + id
                    
                    fetch(url, requestOptions)
                        .then((response) => {
                            response.json()
                        })
                        .then((result) => {
                            console.log(result)
                            this.$router.go()
                        });
                });
        }
    }
})
</script>

<style lang="scss" scoped>
    .wrapper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 38.75rem;
        max-width: 100%;

        label{
            margin-bottom: 1.375rem;
        }
    }
</style>
