<template>
  <div class="waiting-payment">
    <div class="waiting-payment_title">
      <h2>Please, Waiting for payment and generate nft...</h2>
    </div>
    <WaitingErrorStatus v-if="ballanceError"/>
    <PaymentStatus v-else-if="paymentStatus == 'payment'" :transaction="transaction"/>
    <PaymentErrorStatus v-else-if="paymentError" :transaction="transaction"/>
    <WaitingStatus v-else-if="paymentStatus == 'waiting'"/>
   
  </div>
</template>

<script>
import WaitingStatus from "@/components/parts/payment/WaitingStatus.vue";
import WaitingErrorStatus from "@/components/parts/payment/WaitingErrorStatus.vue";
import PaymentStatus from "@/components/parts/payment/PaymentStatus.vue";
import PaymentErrorStatus from "@/components/parts/payment/PaymentErrorStatus.vue";
export default {
  name: "PaymentTransaction",
  props: {
    paymentStatus: String,
    ballanceError: Boolean,
    transaction: String,
    paymentError: String
  },
  components: {
    WaitingStatus,
    WaitingErrorStatus,
    PaymentStatus,
    PaymentErrorStatus
  },
  
};
</script>

<style lang="scss" scoped>
.nextlayout {
  position: absolute;
  top:0;
  left: 0;
  background: $pink;
  color: $white;
}
.waiting-payment {
  padding: 0 0.5rem;
  transition: .5s ease-out;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 0 1rem;
  }
}
.waiting-payment::v-deep a {
  @include smallText;
  color: $blue;
  text-decoration: none;
}
.waiting-payment_title {
  @include creepyTitle;
  padding: 10rem 0 0rem;
  text-align: center;
  h2 {
    color: $pink;
    text-shadow: none;
    font-size: 1.5rem;
  }
 
}
.waiting_status {
  margin-top: 14rem;
}
.waiting-payment_status {
  margin-top: 8rem;
}
.waiting-payment_status-error {
  margin-top: 12rem;
}
.waiting-payment::v-deep .waiting-payment_text {
  color: $dark;
  @include smallText;
  &:not(:first-of-type) {
    margin-top: 0.75rem;
  }
}

 @media only screen and (min-width: 300px) and (max-width: 1200px) {
    .waiting-payment_status {
        margin-top: 10rem;
        
    }
    .waiting_status  {
        margin-top: 16rem;
    }
    .waiting-payment_title {
        padding: 10rem 0 0;
    }
  }
.waiting-payment::v-deep .waiting-payment_text-status {
  margin-top: 0.25rem;
  @include smallText;
  button {
    background: transparent;
    border: none;
    @include smallText;
    color: $blue;
    cursor: pointer;
    font-family: "Roboto Condensed";
  }
  &--error {
    position: relative;
    span {
      color: $red;
    }
    .failed {
      position: absolute;
      top: -0.2rem;
      left: 2.5rem;
      width: 1rem;
      height: 1rem;
      background: url(@/assets/img/icons/failed.png) center center no-repeat;
      background-size: contain;
      display: block;
    }
  }
}

</style>