import {
  createRouter,
  createWebHistory
} from "vue-router";
import Home from "@/views/ProjectsPage.vue";
import AppLayoutAdmin from '@/layouts/AppLayoutAdmin';
import AppLayoutDefault from '@/layouts/AppLayoutDefault';
import AppLayoutPage from '@/layouts/AppLayoutPage';
import AppLayoutError from '@/layouts/AppLayoutError';
import AppLayoutAuc from '@/layouts/AppLayoutAuc';
import Project from "@/views/SingleProject";
import Auc from "@/views/AucPage";
import SingleAucPage from "@/views/SingleAucPage";
// import DefaultPage from "@/views/DefaultPage";

const routes = [
  {
    path: "/",
    name: "Front Page",
    component: AppLayoutDefault,
    meta: {
      layout: AppLayoutDefault,
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: Home,
    meta: {
      layout: AppLayoutAdmin,
      requiresAuth: true
    },
  },
  {
    path: "/:slug",
    name: "Default page",
    component: () => import('@/views/DefaultPage'),
    meta: {
      layout: AppLayoutPage,
    },
  },
  {
    path: "/projects/:slug",
    name: "Project",
    component: Project,
    meta: {
      layout: AppLayoutAdmin,
      requiresAuth: true
    },
  },
  {
    path: "/12apes",
    name: "Auction",
    component: Auc,
    meta: {
      layout: AppLayoutAuc
    }
  },
  {
    path: "/12apes/:id",
    name: "AuctionSingle",
    component: SingleAucPage,
    meta: {
      layout: AppLayoutAuc
    }
  },

  {
    path: "/:pathMatch(.*)*",
    name: "Page not found",
    component: AppLayoutError,
    meta: {
      layout: AppLayoutError,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to) => {
  if(to.meta.requiresAuth){
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-WP-Nonce': localStorage.getItem('nonce')
      },
    };

    const checkAccess = await fetch('https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/check_auth', requestOptions)
      .then(response => response.json())
      .then((result) => {
        return result.access ? result.access : false
      })

    if(checkAccess !== true) {
      return '/'
    }
  }
})

export default router;