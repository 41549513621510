/* eslint-disable */

import Vuex from "vuex";
import global from "@/store/modules/global"
import minting from "@/store/modules/minting"
import wallets from "@/store/modules/wallets"
import blockchain from "@/store/modules/blockchain"
import blockchainAuction from "@/store/modules/blockchainAuction";
import auction from "@/store/modules/auction";
import projects from "@/store/modules/projects";


export default new Vuex.Store({
    modules: {
        namespaced: true,
        global,
        minting,
        wallets,
        blockchain,
        auction,
        projects,
        blockchainAuction
    }
});