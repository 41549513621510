<template>
  <div
    class="mobile-menu_auc"
    :class="getStatusMobileMenu == 'open' ? 'open' : ''"
  >
    <div class="mobile-menu_auc-wrap">
      <div v-if="!connected">
        <button
          class="button mobile-menu_button button--lock"
          :style="!$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
          @click="loginMetamask"
        >
          connect wallet
        </button>
        <a
          target="_blank"
          :href="'https://metamask.app.link/dapp/' + href"
          :style="$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
          class="button mobile-menu_button button--lock"
        >
          Connect wallet
        </a>
      </div>
      <LoginCondition v-else />
      <SocialsList />
    </div>
    <div class="mobile-menu_auc-overlay"></div>
  </div>
</template>

<script>
import LoginCondition from "@/components/parts/auction/LoginCondition.vue";
import SocialsList from "@/components/ui/SocialsList.vue";
export default {
  data: () => ({
    href: "",
    connected: false
  }),
  computed: {
    getStatusMobileMenu() {
      return this.$store.getters.getStatusMobileMenu;
    },
    // getValletConected() {
    //   return this.$store.getters.getValletConected;
    // },
  },
  components: {
    SocialsList,
    LoginCondition,
  },
  methods: {
    conectingMetamask() {
      this.$store.commit("setModalStatus", "");
      this.$store.commit("setStatusMobileMenu", "");
      // this.$store.commit("setSignatureModalStatus", "open");
    },
    getHref() {
      this.href = window.location.href;
    },
    loginMetamask() {
      this.$store.getters.getAuctionWallet.metamaskConnect();
    },
    checkConnect(){
      this.connected = this.$store.getters.getAuctionWallet.wallet.connected
    }
  },
  mounted() {
    this.getHref();
    this.checkConnect()
  },
  watch: {
    '$store.getters.getAuctionWallet.wallet.connected'(){
      this.checkConnect()
    }
  },
};
</script>

<style lang="scss">
.mobile-menu_auc {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  height: 60vh;
  z-index: 3;
  pointer-events: none;
  &.open {
    pointer-events: all;
    .mobile-menu_auc-wrap {
      transform: translateZ(0);
    }
  }
}
.mobile-menu_auc-wrap {
  transition: 0.4s cubic-bezier(0.55, 0, 1, 0.45);
  transform: translate3d(0, calc(-100% - 8rem), 0);
  border-radius: 0 0 0.6rem 0.6rem;
  background-color: $black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile-menu_button {
  font-size: 2.5rem;
  pointer-events: all;
}
</style>