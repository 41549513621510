<template v-if="sound !== ''">
  <button class="btn-sound" @click="playAudio">
    <audio
      ref="audio"
      controls
      :src="sound"
    ></audio>
  </button>
</template>

<script>
export default {
  computed: {
    sound() {
      return this.$store.getters.getSound
    }
  },
  methods: {
    playAudio(event) {
      if (event.target.classList.contains("playing")) {
        this.$refs.audio.pause();
        event.target.classList.remove("playing");
      } else {
        this.$refs.audio.play();
        event.target.classList.add("playing");
      }
    },
  },

  mounted(){
    if (this.$store.getters.getSound == '') {
      this.$store.dispatch("fetchSound")
    }
  }
};
</script>

<style lang="scss">
.btn-sound {
  position: fixed;
  right: 0.9rem;
  bottom: 0.5rem;
  audio {
    display: none;
  }
}
</style>