<template>
  <AucHeaderMobile v-if="$store.getters.getWebVersion == 'mobile'" />
  <AucHeader v-else-if="!connected" />
  <AucHeaderLogin v-else />

  <router-view v-slot="{ Component }">
    <component :is="Component"></component>
  </router-view>
</template>


<script>
import AucHeader from "@/components/headers/auction/AucHeader.vue";
import AucHeaderLogin from "@/components/headers/auction/AucHeaderLogin.vue";
import AucHeaderMobile from "@/components/headers/auction/AucHeaderMobile.vue";
import { useStore } from "vuex";
import { useHead } from '@vueuse/head'

export default {
  name: "AppLayoutAdmin",
  data: () => ({
    connected: false
  }),
  setup() {
    useHead({
      // Can be static or computed
      title: '12 Apes',
      meta: [
        {
          name: "description",
          content: "A collection of unique NFTs on the Ethereum blockchain",
        },
        
      ],
    })
  },
  methods: {
    checkConnect(){
      this.connected = this.$store.getters.getAuctionWallet.wallet.connected
    }
  },
  watch: {
    '$store.getters.getAuctionWallet.wallet.connected'(){
      this.checkConnect()
    }
  },
  mounted(){
    this.checkConnect()
    
    const store = useStore()
    store.commit("getAuctionsInfo");

    if (innerWidth < 1200) {
      this.$store.commit("setWebVersion", 'mobile')
    }
  },
  components: {
    AucHeader,
    AucHeaderLogin,
    AucHeaderMobile
  },
};
</script>