<template>
  <div class="bid-modal" :class="getModalStatus == 'open' ? 'open' : ''">
    <div class="bid-modal_wrap">
      <BidInfo v-if="$store.getters.getAuctionWallet.wallet.connected" :currentNFT="currentNFT" :step="currentNFT.step"/>
      <BidStop v-if="!$store.getters.getAuctionWallet.wallet.connected" />
      <NftView
        :id="currentNFT.id"
        :name="currentNFT.name"
        :path="currentNFT.path"
        :price="currentNFT.price"
        :endTime="currentNFT.end_time"
        :className="'modal'"
       
      />
      <div
        class="modal-close_wrap"
        v-if="getWebVersion == 'mobile'"
        @openBidModal="openBidModal"
      >
        <button class="modal-close" @click="modalClose"></button>
      </div>
      
    </div>
    
    <div class="bid-modal_overlay" @click="closeBidModal"></div>
  </div>
</template>

<script>
import BidInfo from "@/components/parts/auction/modals-part/BidInfo.vue";
import BidStop from "@/components/parts/auction/modals-part/BidStop.vue";
import NftView from "@/components/parts/auction/NftView.vue";
export default {
  name: "BidModal",
  components: {
    NftView,
    BidInfo,
    BidStop,
  },

  props: {
    currentNFT: Object,
    // currentNFTModal: Object
  },
  computed: {
    getModalStatus() {
      return this.$store.getters.getModalStatus;
    },
    getValletConected() {
      return this.$store.getters.getValletConected;
    },
    getWebVersion() {
      return this.$store.getters.getWebVersion;
    },
  },
  methods: {
    closeBidModal() {
      this.$emit('closeModal')
      this.$store.commit("setModalStatus", "");
    },
    modalClose() {
      this.$store.commit("setModalStatus", "");
    },
  },
};
</script>

<style lang="scss">
.bid-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  pointer-events: none;
  &.open {
    pointer-events: all;
    .bid-modal_wrap {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
    .bid-modal_overlay {
      opacity: 1;
      pointer-events: all;
    }
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    overflow-y: scroll;
    display: block;
  }
}
.bid-modal_wrap {
  z-index: 8;
  position: relative;
  background-color: $white;
  display: flex;
  padding: 2.75rem;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
  transform: scale(0.95) translateY(1rem);
  &--signature {
    flex-direction: column;
    width: 40rem;
    text-align: center;
    background: #eeeeee;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    flex-direction: column-reverse;
    padding: 1rem;
    margin-top: 5rem;
    &--signature {
      width: 100%;
      flex-direction: column;
      text-align: start;
    }
  }
}

.bid-modal_overlay {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s cubic-bezier(0.55, 0, 1, 0.45);
}

.modal-close_wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.modal-close {
  width: 1.5rem;
  height: 1.5rem;
  background: url(@/assets/img/icons/close-black.svg) center center no-repeat;
  text-align: end;
}
</style>