<template>
    <label class="radio" :class="isChecked ? 'active' : ''" :value="value"  @click="setValue">
        <input hidden :checked="checked" :name="name" ref="radio" type="radio" :value="value">
        <div class="circle"></div>
        <div class="label">{{label}}</div>
    </label>
</template>

<script>

export default({
    data() {
        return{
            isChecked: this.checked,
        }
    },
    props: [
        'value',
        'name',
        'checked',
        'label'
    ],

    methods: {
        setValue(evt){
            this.$emit('setValue', evt, this.value, this.name)
        }
    },

    mounted() {
        let inputs = document.querySelectorAll(`[name="${this.name}"]`)

        inputs.forEach((input) => {
            input.addEventListener('change', () => {
                if (input == this.$refs.radio){
                    this.isChecked = true
                } else{
                    this.isChecked = false
                }
            })
        })
    }
})
</script>

<style scoped lang="scss">
    .radio{
        display: flex;
        align-items: center;
        margin-right: .5rem;
        padding: 1rem;
        border-radius: .25rem;
        background-color: $darkBlue;
        border: 1px solid $gray3;
        transition: .1s ease-out;
        cursor: pointer; 

        &:hover:not(.active){
            border-color: $white;

            .label{
                color: $white;
            }

            .circle{
                border-color: $white;
            }
        }

        &.active{
            border-color: $green;

            .label{
                color: $green;
            }

            .circle{
                border-color: $green;

                &::before{
                    content: "";
                }
            }
        }
    }

    .label{
        margin-left: 1rem;
        margin-top: .125rem;
        color: $gray3;
        font-size: .875rem;
        line-height: 1rem;
    }

    .circle{
        width: .625rem;
        height: .625rem;
        min-width: .625rem;
        min-height: .625rem;
        border-radius: 100%;
        border: 1px solid;
        border-color: $gray3;
        background-color: transparent;
        position: relative;

        &::before{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: .25rem;
            height: .25rem;
            border-radius: 100%;
            background-color: $green;
        }
    }
</style>
