<template>
  <a
    href="#"
    @click="pushToSinglePage(id)"
    class="grid-loop_item"
    :class="`grid-loop_item--${className}`"
  >
    <div class="grid-loop_item-image">
      <img :width="getWebVersion == 'mobile' ? 414 : 743" :height="getWebVersion == 'mobile' ? 414 : 743" :src="path" :alt="name" />
    </div>

    <div class="grid-loop_item-desc">
      <div>
        <p class="title-nft">{{ name }}</p>
        <p class="price-nft">{{ price }} ETH</p>
      </div>
      <div>
        <AuctionStatus :endTime="endTime" />
        <p class="time"><TimerAuc :endTime="endTime" /></p>
      </div>
    </div>
  </a>
</template>

<script>
import AuctionStatus from "@/components/parts/auction/AuctionStatus.vue";
import TimerAuc from "@/components/parts/auction/TimerAuc.vue";
export default {
  name: "NftView",
  props: {
    name: String,
    price: Number,
    path: String,
    id: Number,
    className: String,
    endTime: Number,
  },
  components: {
    TimerAuc,
    AuctionStatus,
  },
  computed: {
    getWebVersion() {
      return this.$store.getters.getWebVersion;
    },
  },
  methods: {
    pushToSinglePage(id) {
      this.$emit("pushToSinglePage", id);
    },
  },
};
</script>

<style lang="scss">
.grid-loop_item {
  max-width: 57rem;
  cursor: pointer;
  height: max-content;
  text-decoration: none;
  color: $white;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    overflow: hidden;
    transition: 0.3s ease-out;
    margin-bottom: -6px;
  }
  .price-nft,
  .time {
    margin-top: 0.5rem;
  }
  &--modal {
    max-width: 40rem;
    cursor: unset;
    img {
      margin-bottom: -6px;
    }
  }
  @media only screen and (min-width: 1200px) {
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}
.grid-loop_item-image {
  overflow: hidden;
}

.grid-loop_item-desc {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  background-color: $black;
}
</style>