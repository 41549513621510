<template>
  <component :is="headerPart"></component>
</template>

<script>

import MobileHeader from "@/components/headers/graveyard/MobileHeader.vue";
import DesktopHeader from "@/components/headers/graveyard/DesktopHeader.vue";
export default {
  name: "AppLayoutHeader",
  components: {
    MobileHeader,
    DesktopHeader,
  },
  computed: {
    getWebVersion() {
      return this.$store.getters.getWebVersion
    },
    headerPart() {
      let part
      if (this.getWebVersion == 'mobile') {
        part = MobileHeader
      }
      if (this.getWebVersion !== 'mobile') {
        part = DesktopHeader
      }
      return part
    }
  },
  methods: {
    openModal() {
      this.$store.commit("updateStatusModal", "open");
    },
  },
};
</script>
