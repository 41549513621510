<template>
  <div
    @click="setFullImageModal"
    class="full-image_modal"
    :class="getFullImageModal == 'open' ? 'open' : ''"
  >
    <img :src="currentNFT.path" alt="image" />
    <button class="full-image_modal-close" @click="setFullImageModal"></button>
  </div>
</template>


<script>
export default {
  name: "FullImage",
  props: {
    currentNFT: Object,
  },
  computed: {
    getFullImageModal() {
      return this.$store.getters.getFullImageModal;
    },
  },
  methods: {
    setFullImageModal() {
      document.querySelector('body').style.overflow = "unset";
      this.$store.commit("setFullImageModal", "");
    },
  },
};
</script>

<style lang="scss">
.full-image_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 15;
  max-height: 100%;
  overflow-y: scroll;
  transition: all 0.35s ease-in-out;
  &::-webkit-scrollbar {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: -6px;
  }
  &.open {
    opacity: 1;
    pointer-events: all;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    max-height: auto;
    height: 100%;
  }
  .full-image_modal-close {
    background: url(@/assets/img/icons/close-black.svg) center center no-repeat;
    background-size: cover;
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    
  }
}
</style>