<template>
<router-view>
  <div id="convas-container">
    <AppLayoutHeader/>
    <div class="sky" data-sky>
        <div class="stars" data-stars></div>
        <div class="sun" data-sun></div>
        <div class="moon" data-moon></div>
    </div>
    <DefaultPage />
  </div>
</router-view>
</template>


<script>
import AppLayoutHeader from "@/layouts/AppLayoutHeader.vue";
//import DefaultPage from "@/views/DefaultPage.vue";
import { defineAsyncComponent } from 'vue'

export default {
  name: "AppLayout",
  components: {
    AppLayoutHeader,
    DefaultPage: defineAsyncComponent({
      loader: () => import('@/views/DefaultPage.vue')
    })
  },
  
  mounted() {
    let script = document.createElement('script')
    script.src = "/frontt.min.js"
    document.querySelector('body').appendChild(script)

    //проверка на мобилку
    if (innerWidth < 1200) {
      this.$store.commit("setWebVersion", 'mobile')
    }
  },

  unmounted() {
    let script = document.querySelector(`[src="${"/frontt.min.js"}"]`)
    document.querySelector('body').removeChild(script)
  }

};
</script>
