export default {
    state: {
        conection: true,
        pageStatus: false,
        webVersion: '',
        menuMobile: '',
        availablePages: [],
        canvasLoaded: false,
        userWpAuth: false,
        sound: ''
    },
    getters: {
        getConection(state) {
            return state.conection
        },
        getPageStatus(state) {
            return state.pageStatus
        },
        getWebVersion(state) {
            return state.webVersion
        },
        getMenuMobile(state) {
            return state.menuMobile
        },
        getAvailablePages(state) {
            return state.availablePages
        },
        getCanvasStatus(state) {
            return state.canvasLoaded
        },
        getUserWpAuth(state) {
            return state.userWpAuth
        },
        getSound(state) {
            return state.sound
        }
    },
    mutations: {
        setPageStatus(state, newStatus) {
            return state.pageStatus = newStatus
        },
        setWebVersion(state, newVersion) {
            return state.webVersion = newVersion
        },
        setMenuMobile(state, newVal) {
            return state.menuMobile = newVal
        },
        setAvailablePages(state, pages) {
            return state.availablePages = pages
        },
        setCanvasStatus(state) {
            return state.canvasLoaded = true
        },
        setUserWpAuth(state, status) {
            return state.userWpAuth = status
        },
        setSound(state, sound) {
            return state.sound = sound
        }
    },
    actions: {
        fetchPrivatePages({ commit }){
            const requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "X-WP-Nonce": localStorage.getItem("nonce"),
                },
              };
              let myUrl = 'https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/check_access'
              let resp = fetch(myUrl, requestOptions)
                  .then((response) => response.json())
                  .then((data) => {
                      return data
                  })
          
            commit("setAvailablePages", resp)
        },
        fetchSound({ commit }){
            let myUrl = 'https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/get_sound'
            fetch(myUrl)
                .then((response) => response.json())
                .then((data) => {
                    let sound = data.sound ? data.sound : ''
                    commit("setSound", sound)
                })
        }
    }
}