<template>
  <header class="main-header_auc">
    <div class="main-header_auc-item">
    <SocialsList/>
      <a href="https://crypto-graveyard.io/" class="main-header_meta-box-link">
        <img src="@/assets/img/logo.png" alt="graveyard" />
      </a>
    </div>
    <a href="/12apes" class="main-header_auc-logo">
      <img src="@/assets/img/12meta.png" alt="12" />
    </a>

    <button
      class="button main-header_button button--lock"
      :style="!$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
      @click="loginMetamask"
    >
      connect wallet
    </button>
    <a
      target="_blank"
      :href="'https://metamask.app.link/dapp/' + href"
      :style="$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
      class="button main-header_button button--lock"
    >
      connect wallet
    </a>
  </header>
</template>
<script>
import SocialsList from "@/components/ui/SocialsList.vue";
export default {
  name: "AucHeader",
  data: () => ({
    href: "",
  }),
  components: {
    SocialsList,
  },
  methods: {
    loginMetamask() {
      this.$store.getters.getAuctionWallet.metamaskConnect();
    },
    getHref() {
      this.href = window.location.href;
    },
    // conectingMetamask() {
      // this.$store.commit("setSignatureModalStatus", "open");
      
    // },
  },
  mounted() {
    this.getHref();
  }
}
</script>

<style lang="scss">
.main-header_auc {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  .main-header_button {
    all: unset;
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
    font-family: 'Creepster', sans-serif;
    padding: 0.2rem;
    padding-right: 2.5rem;
    border: 2px solid #6E6175;
    color: #F969E6;
    background-color: #C4DBE8;
    border-radius: 0.31rem;
    font-size: 2rem;
    transform: rotate(-3deg);
    margin-bottom: 0.5rem;
    transition: 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    will-change: transform;
    -webkit-tap-highlight-color: transparent;
  }
  &--login {
    align-items: flex-start;
  }
}
.main-header_auc-logo {
  width: 8rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 100%;
    height: 100%;
  }
  &--login {
    top: 0;
    transform: translateX(-50%);
  }
}
.main-header_auc-item {
  display: flex;
  align-items: center;
}

.main-header_meta-box-link {
  height: 3rem;
  margin-left: 1.5rem;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

