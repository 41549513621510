<template>
  <router-view>
    <Preloader 
      v-if="!canvasStatus" 
      :isMain="true"
    />
    <div id="convas-container">
      <AppLayoutHeader/>
      <div class="sky" data-sky>
          <div class="stars" data-stars></div>
          <div class="sun" data-sun></div>
          <div class="moon" data-moon></div>
      </div>
      <div id="mainPage"></div>
      <ButtonSound/>
    </div>
  </router-view>
</template>


<script>
import Preloader from "@/components/modals/PreloaderModal.vue";
import AppLayoutHeader from "@/layouts/AppLayoutHeader.vue";
import ButtonSound from "@/components/ui/ButtonSound.vue";

export default {
  name: "AppLayout",
  data(){
    return {
      privatePages: []
    }
  },
  components: {
    AppLayoutHeader,
    Preloader,
    ButtonSound
  },

  computed: {
    checkLogin(){
      return this.$store.getters.getUserWpAuth
    },
    canvasStatus() {
      return this.$store.getters.getCanvasStatus
    },
    checkHash() {
      return this.$route.hash
    }
  },

  watch: {
    checkLogin(newVal, oldVal){
      if (newVal !== oldVal){
        !newVal ? localStorage.removeItem('nonce') : ''
        setTimeout(() => {this.updateCanvas()}, 100)
      }
    },
    checkHash(){
      if (this.checkHash == '#minting'){
        this.$store.commit("updateStatusModal", "open");
      }
    }
  },

  methods: {
    async updateCanvas(){
      this.$store.dispatch('fetchPrivatePages')
      window.fetchedData = []
      await fetch('https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/get_canvas')
        .then((response) => response.json())
        .then((result) => window.fetchedData = JSON.parse(result))

    
    this.privatePages = await this.$store.getters.getAvailablePages
    let locked = []
    if (Array.isArray(this.privatePages)){
      window.fetchedData.buttons.filter((button) => this.privatePages.every((page) => {
        button.link.indexOf(page) !== -1 ? locked.push(button.id) : false
      }))
    }

    if (locked.length > 0){
      let newData = {...window.fetchedData}
      let color = '';
      newData.imageGroups.filter((group) => locked.every((id) => {
          group.buttons.find((el) => {
            if(!el.lock){
              color = el.rect.fill
            }
            if (el.id == id && el.lock){
              el.rect['fill'] = color
              el.rect.width = el.lock.x
              delete el['lock']
            }
          })
      }));
      window.fetchedData = newData
    }

    const c = document.querySelector('#mainPage');
    if (c) {
        window.initGrave(window.fetchedData, c);
        window.resizeFunc();
    }
    }
  },
  
  mounted() {
    let script = document.createElement('script')
    script.src = "/frontt.min.js"
    document.querySelector('body').appendChild(script)
    document.addEventListener("DOMContentLoaded", () => {
      this.$store.commit("setPageStatus", true)
    });

    this.updateCanvas()

    //проверка на мобилку
    if (innerWidth < 1200) {
      this.$store.commit("setWebVersion", 'mobile')
    }
  },

  unmounted() {
    let script = document.querySelector(`[src="${"/frontt.min.js"}"]`)
    document.querySelector('body').removeChild(script)
  },

}
</script>
