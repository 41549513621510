<template>
  <div class="modal-wrap">
    <div
      class="main-wrapper"
      :class="name == 'payments' ? 'wide' : name == 'access' ? 'access' : ''"
    >
      <component
        @sendData="sendData"
        :props="getProps"
        :is="getComponent"
        @closeModal="closeModal"
      ></component>
    </div>

    <div @click.stop="closeModal(false)" class="modal-close"></div>
  </div>
</template>

<script>
import AccessSettings from "@/components/modals/project/AccessSettings.vue";
import PaymentSettings from "@/components/modals/project/PaymentSettings.vue";
import ProjectSettings from "@/components/modals/project/ProjectSettings.vue";

export default {
  emits: ["closeModal", "sendData"],
  components: {
    AccessSettings,
    PaymentSettings,
    ProjectSettings,
  },
  props: ["name", "post"],

  computed: {
    getComponent() {
      let component =
        this.name == "settings"
          ? ProjectSettings
          : this.name == "payments"
          ? PaymentSettings
          : this.name == "access"
          ? AccessSettings
          : "";
      return component;
    },
    getProps() {
      let settingsProps = {
        title: this.post.acf.title,
        acf: this.post.acf,
      };
      let props = this.name == "settings" ? settingsProps : this.post.acf;
      return props;
    },
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },

  methods: {
    closeModal(status) {
      this.$emit("closeModal", status);
    },


    // Общая функция запроса для всех модалок
    sendData(dataReq) {
      let button = this.$store.getters.getCurrentBtn
      if (button !== null){
        button.innerHTML = 'Send...'
      }
      
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-WP-Nonce": localStorage.getItem("nonce"),
        },
        body: JSON.stringify(dataReq),
      };
      
      fetch(this.apiUrl, requestOptions)
        .then((response) => {
          if (response.ok !== true && button !== null) {
            button.innerHTML = 'Failed'
            button.style.color = 'red'
          } else if(button !== null) {
            button.innerHTML = 'Done'
            button.style.color = '' 
            response.json()
          }
        })
        .then(() => {
          this.$store.dispatch("setCurrentPost", this.post.slug)
          setTimeout(() => {
            if (button !== null){
              button.innerHTML = 'Update'
              button.style.color = '' 
            }
          }, 5000)
        });
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 1.5rem;
  background-color: $black;
  min-width: 41.375rem;
  z-index: 2;
  border-radius: 0.5rem;
  max-height: 90vh;

  &.wide {
    min-width: 55.5rem;
  }

  &.access {
    min-width: 67.3rem;
  }
}

.modal-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  //background-color: rgba(0, 0, 0, .7);
}

.modal-close {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: $darkBlue;
  opacity: 0.7;
}
</style>
