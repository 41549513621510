<template>
    <div ref="gallery" class="gallery">
        <div class="gallery_item" v-for="(item) of gallery" :key="item">
            <div class="image">
                <img :src="item">
            </div>
            <!-- <p class="hash">{{item.hash}}</p> -->
        </div>
    </div>
</template>

<script>

export default({
    props: [
        'link',
        'totalNft'
    ],
    data() {
        return{
            gallery: [],
            imgsCount: 30
        }
    },

    methods: {
        async getImages(count, index) {
            for (let i = index; i < count; i++){
                let image = await fetch(`${this.link}/${i}`)
                    .then((response) => response.json())
                    .then((data) => {
                        return data.image
                })
                this.gallery.push(image)
                // this.gallery.push(`https://graveyard.mypinata.cloud/ipfs/${this.link}/${i}.png`)
            }
        }
    },
    mounted(){
        let index = 0
        if (this.link !== '' ){
            this.getImages(this.imgsCount, index)
            let elRect = {}
            let wh = 0
            let scrollTop = 0

            if(this.$refs.gallery){
                window.addEventListener('scroll', () => {
                    elRect = this.$refs.gallery.getBoundingClientRect();
                    wh = window.innerHeight
                    scrollTop = window.scrollY
                    if(scrollTop + wh + 50 >=  elRect.height && this.imgsCount < this.totalNft){
                        index += 30,
                        this.imgsCount += 30
                        this.getImages(this.imgsCount, index)
                    }
                })
            }
        }
    },

    
})
</script>

<style scoped lang="scss">
    .gallery{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 1.5rem;
        max-width: 100%;
        width: 100%;
        padding: 1.5rem 1.25rem;
        position: relative;
        z-index: 0;

        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 1.25rem;
            height: 1px;
            width: calc(100% - 2.5rem);
            background-color: $gray3;
        }
    }

    .gallery_item{
        overflow: hidden;
        .hash{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 1rem;
            max-width: 14rem;
            color: $white;
            margin-top: .75rem;
        }  

        .image{
            position: relative;
            padding-top: 100%;
            overflow: hidden;

            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
</style>
