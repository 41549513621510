<template>
    <label>
        <slot />
        <div :class="staticValue && inputPosition == 'left' ? 'static left' : staticValue ? 'static': onlyLabels ? 'only-labels': ''" >
            <span v-if="staticValue && staticValue !== ''" class="static-value">{{staticValue}}</span>
            <input :name="name" :style="type == 'search' ? 'padding-right: 3rem;' : ''" :readonly="readonly" :class="readonly ? 'readonly' : ''" type="text" :placeholder="placeholder" v-model="curValue" @change="setValue">
            <div v-if="type === 'search'" class="search"></div>
        </div>
        <button @click="copyText" v-if="copy && copy !== ''" class="copy" type="button">{{copy}}</button>
    </label>
</template>

<script>
import { copyFunction } from '@/components/common/copy-function.js'
export default ({
    data() {
        return{
            string: '',
            curValue: this.value ? this.value : '' 
        }
    },
    props: [
       'placeholder',
       'value',
       'staticValue',
       'inputPosition',
       'copy',
       'readonly',
       'onlyLabels',
       'type',
       'name'
    ],

    computed: {
        copyStr(){
            if(this.staticValue){
                return this.staticValue + this.curValue
            } else{
                return this.value
            }
        }
    },

    methods: {
        copyText(event) {
            copyFunction(event, this.copyStr)
        },

        setValue(evt){
            this.$emit('setValue', evt, this.curValue, this.name)
        }
    },
})
</script>

<style scoped lang="scss">
    label {
        font-size: .875rem;
        line-height: 1rem;
        color: $white;
        width: 100%;
        position: relative;

        input{
            display: block;
            margin-top: .5rem;
            width: 100%;
            border: 1px solid $gray3;
            border-radius: .25rem;
            background-color: $darkBlue;
            padding: .625rem;
            color: $white;
            font-size: 1.185rem;
            line-height: 1.35rem;
            font-weight: 500;
            transition: .1s ease-out; 

            &:hover:not(.readonly){
                border-color: $white;
            }

            &::placeholder{
                color: $gray3;
            }

            &:focus:not(.readonly){
                outline: none;
                border-color: $gray3;
                background-color: $black;
            }

            &.readonly{
                pointer-events: none;
                &:focus{
                    outline: none;
                }
            }
        }
    }

    .static{
        display: flex;
        align-items: flex-end;
        input{
            flex-grow: 1;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.left {
            flex-direction: row-reverse;
            input{
                border-radius: .25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .static-value{
                border-radius: .25rem;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: 1px solid $gray3;
                border-left: none;
            }
        }
    }

    .static-value{
        background-color: transparent;
        border: 1px solid $gray3;
        border-radius: .25rem;
        border-right: none;
        padding: .625rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: $gray2;
        font-size: 1.185rem;
        line-height: 1.35rem;
        font-weight: 500;
        margin-top: .5rem;
        min-width: max-content;
    }

    .copy{
        position: absolute;
        top: 0;
        right: 0;
        color: $gray3;
        font-size: .875rem;
        line-height: 1rem;
        background-color: transparent;
        padding-left: 1.25rem;

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 1rem;
            background: url('@/assets/img/icons/copy.svg') center center no-repeat;
            background-size: 100% auto;
        }
    }

    .only-labels{
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }

    .search{
        height: calc(100% - .5rem);
        position: absolute;
        bottom: 0;
        right: 0;
        width: 2.5rem;
        background: url('@/assets/img/icons/search.svg') center center no-repeat; 
        background-color: transparent;
    }
</style>
