//copy function

export const copyFunction = (ev, string, ) => {
    let text = string;
    const copyToClipboard = (str) => {
        const el = document.createElement("textarea");
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }; 
    
    // ev.target.innerText = "(Copied)";
    copyToClipboard(text);
}