<template>
  <div class="image-loop_wrapper">
    <div class="image-loop_wrapper-switcher">
      <p v-if="getWebVersion !== 'mobile'">Preview</p>
      <button
        :class="getLoopStatus == 'grid' ? 'active' : ''"
        class="switcher-button switcher-button--grid"
        @click="setLoopStatus('grid')"
      ></button>
      <button
        :class="getLoopStatus == 'table' ? 'active' : ''"
        class="switcher-button switcher-button--table"
        @click="setLoopStatus('table')"
      ></button>
    </div>
    <component :is="pathOfLoop" @openBidModal="openBidModal" @pushToSinglePage="pushToSinglePage"></component>
  </div>
</template>

<script>
import GridLoop from "@/components/parts/auction/image-loop/GridLoop.vue";
import TableLoop from "@/components/parts/auction/image-loop/TableLoop.vue";
import TableMobileLoop from "@/components/parts/auction/image-loop/TableLoopMobile.vue"
export default {
  name: "ImageLoop",
  components: {
    GridLoop,
    TableLoop,
  },
  computed: {
    getLoopStatus() {
      return this.$store.getters.getLoopStatus;
    },
    pathOfLoop() {
      let path;
      if (this.getLoopStatus == "grid") {
        path = GridLoop;
      }
      if (this.getLoopStatus == "table") {
        if (this.getWebVersion !== "mobile") {
          path = TableLoop;
        } else {
          path = TableMobileLoop
        }
      }
      return path;
    },
    getWebVersion() {
      return this.$store.getters.getWebVersion;
    },
  },
  methods: {
    setLoopStatus(name) {
      this.$store.commit("setLoopStatus", name);
    },
    openBidModal(id) {
      this.$emit("openBidModal", id)
    },
    pushToSinglePage(id) {
      this.$emit("pushToSinglePage", id);
    }
  },
};
</script>

<style lang="scss" scoped>
.image-loop_wrapper {
  background-color: $black;
  padding: 3rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 0;
  }
}
.image-loop_wrapper-switcher {
  @include bodyTextAuction;
  color: $white;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 0.75rem;
  }
}
.switcher-button {
  width: 3rem;
  height: 3rem;
  border: 1px solid $gray4;
  background-color: transparent;
  border-radius: 2px;
  margin-left: 1.5rem;
  &--grid {
    background-image: url(@/assets/img/icons/grid.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }
  &--table {
    background-image: url(@/assets/img/icons/table.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }
  &.active {
    border: 1px solid $white;
    &.switcher-button--grid {
      background-image: url(@/assets/img/icons/active-grid.svg);
      background-repeat: no-repeat;
    }
    &.switcher-button--table {
      background-image: url(@/assets/img/icons/active-table.svg);
      background-repeat: no-repeat;
    }
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-left: 0rem;
    &:not(:first-of-type) {
      margin-left: 1.5rem;
    }
  }
}
</style>