<template>
  <AppLayout />
  <!-- <AppLayoutError/> -->
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
// import AppLayoutError from "@/layouts/AppLayoutError.vue";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    AppLayout,
    // AppLayoutError
  },
  setup() {
    const store = useStore();
    store.commit("createContract", true);
    store.commit("createWallet", true);
    
    store.commit("createAuctionContract", true);
    store.commit("createAuctionWallet", true);
  },
};
</script>

