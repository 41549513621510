<template>
  <div>
    <!-- <p class="live live--wait" v-if="wait">Wait</p> -->
    <p class="live live--live" v-if="endTime !== 0 && endTime > currentTime">
      Live Auction
    </p>
    <p class="live live--ready" v-if="endTime == 0">Ready</p>
    <p class="live live--sold" v-if="endTime < currentTime && endTime !== 0">
      Sold
    </p>
  </div>
</template>

<script>
export default {
  name: "AuctinStatus",
  data(){
    return{
      currentTime: (new Date().getTime() / 1000).toFixed(0)
    }
  },
  props: {
    endTime: Number,
  },
  watch: {
    currentTime(){
      if (this.endTime <= this.currentTime){
        this.$forceUpdate()
      }
    },
    endTime(){
      if (this.endTime !== 0 && this.endTime >= this.currentTime){
        setInterval(() => {
          this.currentTime = (new Date().getTime() / 1000).toFixed(0);
        }, 1000)
      }
    }
  },
  computed: {
    // currentTime() {
    //   let currentTime = new Date().getTime() / 1000;
    //   return currentTime.toFixed(0);
    // },
  },
  mounted(){
    if (this.endTime !== 0 && this.endTime >= this.currentTime){
      setInterval(() => {
        this.currentTime = (new Date().getTime() / 1000).toFixed(0);
      }, 1000)
    }
    
  }
};
</script>

<style lang="scss">
.live {
  position: relative;
  padding-left: 1rem;
  @include bodyTextAuction;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(45deg);
    width: 0.5rem;
    height: 0.5rem;
  }
  &--ready {
    &::before {
      background-color: $green1;
      
    }
  }
  &--live {
    &::before {
      animation: live 1s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
      background-color: $green1;
    }
  }
  &--sold {
    &::before {
      background-color: $red;
      
    }
  }
  // &--wait {
  //   &::before {
  //     background-color: $orange;
  //   }
    
  // }
}
.end-of-auc {
  .live {
    font-size: 1.5rem;
    font-weight: bold;
    &--sold {
      &::before {
        background-color: $red;
        @media only screen and (min-width: 300px) and (max-width: 1200px) {
          left: 0rem;
        }
      }
    }
    &--ready {
      &::before {
        background-color: $green1;
        @media only screen and (min-width: 300px) and (max-width: 1200px) {
          left: 0rem;
        }
      }
  }
  }
}
</style>