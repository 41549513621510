<template>
  <div v-if="this.currentNFT"
    class="auc-single-page auction"
    :class="
      getModalStatus == 'open' ||
      getSignatureModalStatus == 'open' ||
      getFullImageModal == 'open'
        ? 'auc-single-page--overflow'
        : ''
    "
  >
  
    <NftHello
      :currentNFT="currentNFT"
      :siblingElems="siblingElems"
      @changeNft="changeNft"
    />
    <BidBlock :currentNFT="currentNFT" @openBidBlockModal="openBidModal" @closeModal="currentNFTModal = null" :id="id"/>
    <CollectionBlock @openBidModal="openBidModal" @pushToSinglePage="pushToSinglePage"/>
    <BidModal v-if="currentNFTModal !== null" :currentNFT="currentNFTModal" @closeModal="currentNFTModal = null"/>
  </div>
  <div v-else class="wrap-404">
    <h1 class="wrap-404_title">404</h1>
    <div class="wrap-404_content">
      <p>The page you’re looking for can’t be found.</p>
    </div>
    <a href="/" target="" class="button button--secondary"
      >Back to the graveyard</a
    >
  </div>
</template>

<script>
import NftHello from "@/modules/AucModules/NftHello.vue";
import BidBlock from "@/modules/AucModules/BidBlock.vue";
import CollectionBlock from "@/modules/AucModules/CollectionBlock.vue";
import BidModal from "@/components/modals/auction/BidModal.vue";
export default {
  name: "AucSinglePage",
  components: {
    NftHello,
    BidBlock,
    CollectionBlock,
    BidModal
  },
  data() {
    return {
      id: this.$route.params.id,
      modalId: null,
      currentNFT: {},
      currentNFTModal: null
    };
  },
  computed: {
    arrayNft() {
      return this.$store.getters.getNft;
    },
    // currentNFTModal() {
    //   let currentNftModal = this.arrayNft.find((item) => item.id == this.modalId);
    //   return currentNftModal;
    // },
    siblingElems() {
      let currentNft = this.arrayNft.find((item) => item.id == this.id);
      const indexNft = this.arrayNft.indexOf(currentNft);
      const siblings = {
        next: this.arrayNft[indexNft + 1],
        prev: this.arrayNft[indexNft - 1],
      };
      return siblings;
    },
    getModalStatus() {
      return this.$store.getters.getModalStatus;
    },
    getSignatureModalStatus() {
      return this.$store.getters.getSignatureModalStatus;
    },
    getFullImageModal() {
      return this.$store.getters.getFullImageModal;
    },
  },
  methods: {
    changeNft(newId) {
      this.id = newId;
      //this.$router.push({ path: `/auction/${newId}#` });
    },
    openBidModal(id) {
      this.currentNFTModal = id;
      this.$store.commit("setModalStatus", "open");
      // this.$router.push({ path: `/auction/${this.id}` });
    },
    openBidBlockModal(id) {
      this.modalId = id
      this.$store.commit("setModalStatus", "open");
    },
    pushToSinglePage(id) {
      this.id = id
      window.scrollTo(0, 0)
      this.$router.push({ path: `/12apes/${id}#` });
      
    }
  },
  watch: {
    id(){
      this.currentNFT = this.arrayNft.find((item) => item.id == this.id);
    },
    modalStatus(newVal) {
        if (newVal == 'open'){
            document.querySelector('body').style.overflow = 'hidden'
        } else{
            document.querySelector('body').style.overflow = 'unset'
        }
    },
  },
  mounted(){
    this.currentNFT = this.arrayNft.find((item) => item.id == this.id);
  }
};
</script>

<style lang="scss" scoped>
.auc-single-page {
  position: relative;
  background-color: $black;
  // &--overflow {
  //   overflow: hidden;
  //   height: 100%;
  // }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    // &--overflow {
    //   overflow: hidden;
    //   height: calc(100vh - 5rem);
    // }
  }
}
</style>