<template>
    <div class="waiting_status">
      <p class="waiting-payment_text">
        Please, follow instructions in your wallet to complete payment.
      </p>
      <p class="waiting-payment_text">Status</p>
      <p class="waiting-payment_text-status">Waiting for payment...</p>
    </div>
</template>
<script>
export default {
    name: "WaitingStatus",
}
</script>