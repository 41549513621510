<template>
    <ModalHead 
        @closeModal="closeModal"
    >
        <h2>Project Settings</h2>
    </ModalHead>
        <form ref="projectSettings" class="content-wrapper">
        <InputText
            :value="props.acf.mint_link"
            staticValue="crypto-graveyard.io/"
            copy="Copy link"
            name="mint_link"
        >
            Mint links
        </InputText>
        <InputText
            :value="props.acf.title !== '' ? props.acf.title : 'Untitled Project'"
            name="title"
        >
            Project Name
        </InputText>
        <TextArea
            :value="props.acf.description !== '' ? props.acf.description : ''"
            placeholder="Prehistoric troublemakers, on chain"
            :name="'description'"
        >
            Project Description
        </TextArea>
        <InputText
            :value="$store.getters.getCurProjectContract.baseURI"
            placeholder="IPFS link"
            name="ipfs_link"
        >
            IPFS Link
        </InputText>
        <InputText
            :value="$store.getters.getCurProjectContract.maxValue"
            placeholder="NFT Total"
            name="nft_total"
        >
            NFT Total
        </InputText>
        </form>
    <div class="buttons">
        <ButtonUi
            buttonClass="green"
            @click="sendData"
        >Update</ButtonUi>
        <!-- <ButtonUi
            buttonClass="red"
            @click="deleteProject"
        >Delete project</ButtonUi> -->
    </div>
</template>

<script>
import ModalHead from '@/components/modals/project/ModalHead.vue'
import InputText from '@/components/ui/InputText.vue'
import ButtonUi from '@/components/ui/ButtonUi.vue'
import TextArea from '@/components/ui/TextArea.vue'

export default ({
    emits: ["closeModal", "sendData"],
    props: [
        'props'
    ],
    components: {
        ModalHead,
        InputText,
        ButtonUi,
        TextArea
    },
    computed: {
        projectUrl(){
            return this.$store.getters.getPostUrl
        }
    },

    methods:{
        closeModal(status){
            this.$emit('closeModal', status)
        },
        sendData(evt){
            let formEls = this.$refs.projectSettings.elements
            let formData = {}

            Array.from(formEls).forEach(el=> {
                formData[encodeURIComponent(el.name)] = el.value
            });
            let data = {
                "acf": formData
            }

            if (formData.nft_total != this.$store.getters.getCurProjectContract.maxValue){
                this.$store.getters.getCurProjectContract.setMaxValue(formData.nft_total)
            }
            if (formData.ipfs_link != this.$store.getters.getCurProjectContract.baseURI){
                this.$store.getters.getCurProjectContract.setBaseURI(formData.ipfs_link)
            }

            this.$store.commit('setCurrentBtn', evt.target)
            this.$emit('sendData', data)
        },
        deleteProject(){
            const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json",  'X-WP-Nonce': localStorage.getItem('nonce')}
            };

            fetch(this.projectUrl, requestOptions)
                .then((response) => response.json())
                .then((data) => (console.log(data)));

            this.$router.push('/projects')
        }
    }
})
</script>

<style scoped lang="scss">
    h2{
        font: inherit;
    }

    .content-wrapper{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1.125rem;
        padding-bottom: 1.75rem;
        border-bottom: 1px solid $darkBlue;
        padding-top: 1.75rem;
    }

    .buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1.75rem;
    }
</style>

