<template>
    <button :style="disable ? 'pointer-events: none; opacity: .7;' : ''" :class="buttonClass" type="button" class="button-admin">
        <slot />
    </button>
</template>

<script>

export default({
    props: {
        buttonClass: {
            type: String,
            default: 'green'
        },
        disable: {
            type: Boolean,
            default: false
        }
    }
})
</script>

<style scoped lang="scss">
    .button-admin{
        outline: none;
        border: none;
        padding: .625rem;
        border-radius: .625rem;
        font-size: 1.125rem;
        line-height: 1.275rem;
        font-weight: bold;
        cursor: pointer;
        transition: .1s ease-out;

        &:disabled{
            pointer-events: none;
            opacity: .7;
        }

        &.green {
            color: $green;
            background-color: $white;
        }

        &.red {
            color: $red;
            background-color: $white;
        }

        &.gray {
            color: $gray;
            background-color: $white;
        }

        &.transparent{
            color: $white;
            background-color: transparent;
            border: .125rem solid $gray2;
        }

        &:hover{
            box-shadow: 0 0 .5rem 0 rgba(34, 60, 80, 0.8) inset;
        }

        &.big{
            padding: 1rem 2rem;
            font-size: 1.5rem;
            line-height: 1.75rem;
        }
    }
</style>
