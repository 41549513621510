<template>
    <header class="header">
        <slot 
            @setName="$emit('setName', name)"
        />
        <div class="logo">
            <img src="@/assets/img/logo.svg">
        </div>
    </header>
</template>

<style lang="scss" scoped>
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .625rem 1rem;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: $black;
    }

    .logo{
        height: 100%;

        img{
            max-height: 3rem;
            display: block;
        }
    }
</style>