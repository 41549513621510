<template>
    <div class="waiting_status">
      <p class="waiting-payment_text">
        Please, follow instructions in your wallet to complete payment.
      </p>
      <p class="waiting-payment_text">Status</p>
      <p class="waiting-payment_text-status waiting-payment_text-status--error">
        <span>Error.</span>You don’t have enough ETH in your wallet.
      </p>
    </div>
</template>
<script>
export default {
    name: "WaitingErrorStatus"
}
</script>