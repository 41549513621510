<template>
    <div class="waiting-payment_status">
      <p class="waiting-payment_text">
        Waiting for your payment to be finished. Usually it takes few minutes.
        If you’ll accidently close this window, you will find your token in your
        <a :href="openseaAccountUrl" target="_blank">OpenSea account</a>.
      </p>
      <p class="waiting-payment_text">Status</p>
      <p class="waiting-payment_text-status">Waiting for payment...</p>
      <a :href="`${polygonUrl}tx/${transaction}`" target="_blank">View in blockchain</a>
      <p class="waiting-payment_text">Transaction ID:</p>
      <p class="waiting-payment_text-status">
        <span>{{ maskTransaction }}</span>
        <button @click="copyText">(Copy)</button>
      </p>
    </div>
</template>
<script>
import { copyFunction } from "@/components/common/copy-function.js";
export default {
    name: "PaymentStatus",
    data() {
        return {
            copyFunction,
            openseaAccountUrl: "",
            polygonUrl: ""
        }
        
    },
    computed: {
        maskTransaction() {
            let strFirst = this.transaction.substr(0, 10);
            let strSecond = this.transaction.substr(55, 66);
            let maskStr = strFirst + '...' + strSecond;
            return maskStr
        }
    },
    props: {
        transaction: String,
    },
    methods: {
        copyText(event) {
            copyFunction(event, this.transaction)
        }
    },
    mounted(){
        this.openseaAccountUrl = process.env.VUE_APP_OPENSEA_ACCOUNT
        this.polygonUrl = process.env.VUE_APP_POLYGON_URL
    }
}
</script>