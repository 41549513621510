<template>
    <div class="projects">
        <div class="project-wrap">
            <button type="button" class="project add-new" @click="$emit('openModal', true)">
                Add new project
                <span class="plus"></span>
            </button>
        </div>

        <div  v-for="project of projects" :key="project.id" class="project-wrap">
            <router-link :to="'/projects/'+ project.slug" class="project">
                <span style="position: relative; z-index: 1;">{{project.acf.title}}</span>
                <img class="preview" src="@/assets/img/preview.png">
            </router-link>
        </div>
    </div>
</template>

<script>

export default ({
    emits: ["openModal"],
    computed: {
        projects(){
            return this.$store.getters.getProjects
        }
    }, 

    async created() {
        await this.$store.dispatch("fetch");
    }
})
</script>

<style scoped lang="scss">
    .projects{
        padding: 3rem 1rem;
        min-height: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1.5rem;
    }

    .project-wrap{
        position: relative;
        padding-top: 60%;
        background-color: transparent;
        overflow: hidden;
    }

    .project{
        position: absolute;
        text-decoration: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: $white;
        border-radius: .5rem;
        overflow: hidden;
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: bold;
        border: 1px solid $white;
    }

    .add-new{
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: .25rem;
        background: url('@/assets/img/border.svg') center center no-repeat;
        background-size: 100%;
        border: none;

        &:focus{
            outline: none;
            border: none;
        }
    }

    .plus{
        display: block;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 1rem;

        &::before,
        &::after{
            position: absolute;
            content: "";
            background-color: $white;
        }

        &::before{
            height: 100%;
            width: .25rem;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &::after{
            width: 100%;
            height: .25rem;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .preview{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
</style>
