<template>
    <div class="wrapper">
        <div v-if="label" class="label">{{label}}</div>
        <slot/>
    </div>
</template>


<script>

export default ({
    props: [
        'label'
    ]
})
</script>

<style scoped lang="scss">
    .label{
        font-size: .875rem;
        line-height: 1rem;
        color: $white;
        width: 100%;
        margin-bottom: .5rem;
    }

    .wrapper{
        display: flex;
        flex-wrap: wrap;
    }
</style>
