export default {
    state: {
        wallets: [],
    },
    getters: {
        getWallets(state) {
            return state.wallets
        },
    },
    mutations: {
        setWallets(state, newVal) {
            state.wallets = newVal
        },
    }
}