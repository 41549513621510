<template>
  <!-- <div class="modal-overlay"> -->
    <div class="modal">
      <div class="modal-header">
        <p class="modal-header_text">Complete your minting</p>
        <button class="modal-header_close" @click="closeModal"></button>
      </div>
      <div
        class="modal-container"
        :class="!getConection || getMintingStatus == 'public mint' ? 'modal-container--error' : ''"
      >
        <ul
          class="modal-container_nav"
          v-if="paymentStatus != 'waiting' && paymentStatus != 'payment' && paymentStatus != 'success' && !noInternet && !statusError"
        >
          <li class="modal-container_nav-item" :class="checkLogin ? 'modal-container_nav-item--disabled' : ''">
            1. Connect wallet
          </li>
          <li class="modal-container_nav-item" :class="!checkLogin ? 'modal-container_nav-item--disabled' : ''">
            2. Minting NFTs
          </li>
        </ul>
        <div class="modal-container_content">
          <slot />
        </div>
        
        <svg
          class="shape-overlays"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#00c99b" />
              <stop offset="100%" stop-color="#ff0ea1" />
            </linearGradient>
            <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#ffd392" />
              <stop offset="100%" stop-color="#ff3898" />
            </linearGradient>
            <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stop-color="#110046" />
              <stop offset="100%" stop-color="#32004a" />
            </linearGradient>
          </defs>
          <path class="shape-overlays__path"></path>
          <path class="shape-overlays__path"></path>
          <path class="shape-overlays__path"></path>
        </svg>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
// import {animateState} from '@/components/common/animation-states';
import { mapGetters } from "vuex";
export default {
  name: "AppModal",
  computed: mapGetters([
    "getConection",
    "getMintingStatus",
  ]),
  props: {
    paymentStatus: String,
    paymentError: String,
    balanceError: Boolean,
    transaction: String,
    checkLogin: Boolean,
    noInternet: Boolean,
    statusError: Boolean
  },
  methods: {
    closeModal() {
      // animateState(true)
      this.$store.commit("updateStatusModal", 'close')
      this.$router.push({hash: ''})
    }
  }
};
</script>

<style lang="scss">
// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 9;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   opacity: 0;
//   visibility: hidden;
//   pointer-events: none;
//   transition: all .5s ease-in-out;
//   &.active {
//     opacity: 1;
//     visibility: visible;
//     pointer-events: all;
//   }
//   @media only screen and (min-width: 300px) and (max-width: 1200px) {
//     align-items: unset;
//     justify-content: unset;
//   }
// }

.modal {
  z-index: 1;
  width: 20rem;
  border: 1px solid $black;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  min-height: 39rem;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1200px) {
    min-width: 20rem;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    width: 100%;
    border-radius: 0;
    border: none;
    top: 0;
    left: 0;
    transform: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: unset;
    min-height: 100vh;
  }
}

.modal-header_text {
  font-weight: 700;
}
@media only screen and (min-width: 300px) and (max-width: 1200px) {
  .modal-container_content {
    height: 100%;
  }
}
.modal-header {
  display: flex;
  align-items: center;
  background-color: $orange;
  padding: 1.75rem 0.8rem;
  justify-content: space-between;
}

.modal-header_close {
  width: 0.875rem;
  height: 0.875rem;
  display: block;
  background: url(@/assets/img/icons/close.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.modal-container {
  background-color: $light-blue;
  position: relative;
  z-index: 0;
  padding: 1rem 0.5rem;
  flex-grow: 1;
  &--error {
    background-color: $orange;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.modal-container_nav {
  display: flex;
  align-items: center;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 1rem 0.5rem;
  }
}

.modal-container_nav-item {
  background-color: $white;
  font-size: 1rem;
  padding: 0.625rem;
  color: $black;
  font-weight: 700;
  border: 1px solid #000000;
  border-radius: 0.625rem;
  text-decoration: none;
  list-style: none;
  &:not(:first-of-type) {
    margin-left: 0.625rem;
  }
  &--disabled {
    pointer-events: none;
    color: $black;
    opacity: 0.5;
  }
}
</style>