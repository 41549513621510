<template>
  <div class="login-meta-mask">
    <button
      class="login-meta-mask_item button button--shadow"
      :style="!$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
      @click="loginMetamask"
    >
      <span ref="metamask"></span>
      <p>Login with MetaMask</p>
    </button>
    <a
      target="_blank"
      :href="'https://metamask.app.link/dapp/' + href"
      :style="$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
      class="
        login-meta-mask_item login-meta-mask_item--notInstalled
        button button--shadow
      "
    >
      <p>Login with MetaMask</p>
    </a>

    <p class="login-meta-mask_text">
      <!-- Connect your wallet or create a new one to start collecting NFTs on the
      Polygon blockchain. By connecting your wallet you aggree to our
      <a href="">Terms of service.</a> -->
    </p>
  </div>
</template>


<script>
// import {animateState} from '@/components/common/animation-states';
const ModelViewer = require("metamask-logo");

export default {
  name: "LoginMetaMask",
  data: () => ({
    href: "",
  }),
  methods: {
    loginMetamask() {
      this.$store.getters.getWallet.metamaskConnect();
    },
    getHref() {
      this.href = window.location.href;
    },
  },
  mounted() {
    this.getHref();

    // initializing and appending the MetaMask Logo to its Div
    let metaMaskViewer = ModelViewer({
      pxNotRatio: true,
      width: 70,
      height: 55,
      followMouse: false,
      slowDrift: false,
    });
    this.$refs.metamask.appendChild(metaMaskViewer.container);
    metaMaskViewer.lookAt({
      x: 100,
      y: 100,
    });
    // enable mouse follow
    metaMaskViewer.setFollowMouse(true);

    // deallocate nicely
    metaMaskViewer.stopAnimation();

    // animateState(".login-meta-mask_item")
  },
};
</script>


<style lang="scss" scoped>
.login-meta-mask {
  margin: 0 1rem;
  transition: .5s ease-out;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.login-meta-mask_item {
  font-size: 2.875rem;
  margin-top: 3rem;
  color: $white;
  text-align: center;
  background-color: $pink;
  padding-top: 2rem;
  padding-bottom: 3rem;
  background-position: center 20%;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-right: 2.5rem;
    margin-left: 2rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    margin-right: 2.5rem;
    margin-left: 2rem;
    width: 50%;
    margin: 3rem auto;
  }
  &--notInstalled {
    margin-top: 6rem;
    padding-top: 3rem;
  }
}

.login-meta-mask_text {
  margin: 6.2rem -1rem 0;
  @include smallText;
  a {
    text-decoration: none;
    color: $blue;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 1rem .5rem;
    margin: 0rem -1rem 0;
  }
}
</style>