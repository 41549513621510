<template>
  <div class="success-block">
    <div class="success-block_logo">
      <img src="@/assets/img/logo.svg" />
    </div>
    <div class="success-block_title">
      <h2>Welcome to GRAVEYARD NFT community! NFT is in your wallet</h2>
    </div>
    <p class="success-block_text">The article was successfully minted to</p>
    <a href="#" @click="copyText" class="success-block_link">{{ maskTransaction }}</a>
    <p class="success-block_text success-block_text--bold">Private content on the site and early access to projects are available for you!</p>
    <img class="cloud cloud--first" src="@/assets/img/cloud.png">
    <img class="cloud cloud--second" src="@/assets/img/cloud.png">
    <img class="cloud cloud--third" src="@/assets/img/cloud.png">
  </div>
</template>
<script>
import { copyFunction } from "@/components/common/copy-function.js";
export default {
  name: "SuccessStatus",
  props: {
    transaction: String
  },
  computed: {
    maskTransaction() {
      let strFirst = this.transaction.substr(0, 10);
      let strSecond = this.transaction.substr(55, 66);
      let maskStr = strFirst + '...' + strSecond;
      return maskStr
    }
  },

  methods: {
    copyText(event) {
      copyFunction(event, this.transaction)
    },
  },
  data() {
      return {
          copyFunction
      }
      
  },

};
</script>
<style lang="scss" scoped>
.cloud {
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  animation: cloud 10s linear infinite;
  &--first {

  }
  &--second {
    top: 60%;
    left: 30%;
  }
  &--third {
    top: 20%;
    left: 70%;
  }
}
@keyframes cloud {
    0% {
        transform: translateX(10vw);
    }
    100% {
        transform: translateX(-20vw);
    }
}
.success-block {
  transition: .5s ease-out;
  background-image: url(@/assets/img/success-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin: -1rem -0.5rem;
  padding: 1rem 0.5rem;
  position: relative;
  background-position: bottom;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url(@/assets/img/HTjD4.gif) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    height: 100%;
    overflow: hidden;
    margin: 0;
  }
}
.success-block_logo {
  width: 19rem;
  height: 5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    width: 100%;
  }
}
.success-block_title {
  margin-top: 5.875rem;
  h2 {
    @include h2;
    text-shadow: none;
    color: $white;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: center;
    font-weight: 400
  }
}
.success-block_text {
  @include bodyText;
  line-height: 1.5rem;
  color: white;
  text-align: center;
  margin-top: 1rem;
  font-weight: 400;
  &--bold {
    font-weight: 700;
    padding: 0 3rem;
    margin-top: 8rem;
    @media only screen and (min-width: 300px) and (max-width: 1200px) {
      margin-top: 10.5rem;
    }
  }
}
.success-block_link {
  @include bodyText;
  color: $white;
  line-height: 1.5rem;
  font-weight: 400;
  text-align: center;
  display: block;
}
</style>