<template>
  <div class="network-error">
    <component :is="errorParts"></component>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
import ErrorMintingStatus from '@/components/parts/error/ErrorMintingStatus.vue';
import ErrorConection from '@/components/parts/error/ErrorConection.vue';
export default {
  name: "NetworkError",
  props: {
    noInternet: Boolean,
    statusError: Boolean
  },
  components: {
    ErrorConection,
    ErrorMintingStatus
  },
  computed: {
    // ...mapGetters(["getMintingStatus", "getConection", "getIsMetaMask"]),
    errorParts() {
      let errorPart
      if (this.noInternet) {
        errorPart = ErrorConection
      } else if (this.statusError) {
        errorPart = ErrorMintingStatus
      }
      return errorPart
    },
  },
  
};
</script>
<style lang="scss" scoped>
.network-error::v-deep .network-error_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12.25rem 3rem;
  a {
    color: $black;
    
  }
}
.network-error::v-deep .network-error_container-text {
  @include smallText;
  font-weight: 400;
  text-align: center;
  &--bold {
    font-weight: 700;
    @include bodyText;
    margin-bottom: 1.25rem;
  }
}
.network-error::v-deep .network-error_container-button {
  font-size: 1.5rem;
  border-radius: 0.625rem;
  margin-top: 1.8rem;
}
</style>