<template>
    <div class="wrapper" :class="tabs ? 'border' : ''">
        <div class="title">
            <slot />
        </div>
        <button @click="closeModal(false)" type="button" class="close"></button>
    </div>
</template>

<script>

export default ({
    emits: ["closeModal"],
    props: [
        'tabs'
    ],

    methods:{
        closeModal(status){
            this.$emit('closeModal', status)
        }
    },

})
</script>


<style scoped lang="scss">
    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.border{
            padding-bottom: 1.75rem;
            border-bottom: 1px solid $darkBlue;
        }
    }

    .title{
        color: white;
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    

    .close{
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;
        background-color: transparent;

        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: .125rem;
            background-color: $white;
            transform: translateX(.565rem) rotate(-45deg);
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: .125rem;
            background-color: $white;
            transform: translateX(-.565rem) rotate(45deg);
        }
    }

</style>