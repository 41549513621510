<template>
  <div class="сreators-component">
    <div class="сreators-component_wrap">
      <div class="creators">
        <p class="creators-capture">Created by</p>
        <a href="https://twitter.com/mashkow1?s=21&t=lj0HigR5254n0uaf54khNQ" class="creators-item">
          <div class="creators-item_icon">
            <img src="@/assets/img/mashkow.png" alt="mashkow" />
          </div>
          <p class="creators-item_name creators-item_name--uppercase">
            Mashkow
          </p>
        </a>
      </div>
      <div class="creators">
        <p class="creators-capture">Collection</p>
        <a href="https://opensea.io/collection/twelveapes" class="creators-item">
          <div class="creators-item_icon">
            <img src="@/assets/img/ava.png" alt="monkeys" />
          </div>
          <p class="creators-item_name">12 Apes</p>
        </a>
      </div>
    </div>
    <p class="сreators-component_address">
      Contract Address <a :href="polygonUrl + 'address/' + contract" target="_blank">{{ maskTransaction }}</a>
    </p>
    <div class="сreators-component_description">
      <h2 class="сreators-component_description-title">Description</h2>
      <p class="сreators-component_description-text">
        MASHKOW 12 Apes is a commentary on the current state of web3 development, and how celebrity status has changed with NFTs, an industry that has allowed owners of “blue-chip” NFT projects to promote their own brand and following with the elite status of the collection itself. Mashkow's collection is a series of 12 1/1 tokenized oil paintings commemorating notable celebrities who own the distinguished and most prominent NFT collection, Bored Ape Yacht Club, fused with their digitalized BAYC counterparts. This collection is the start of what represents celebrity status, as we enter an age where web3 presence has emerging dominance in fame and fortune.
      </p>
    </div>
    <div class="сreators-component_share">
      <button class="button-share" @click="shareModalStatus">Share</button>
      <ShareModal/>
    </div>
  </div>
</template>

<script>
import ShareModal from "@/components/modals/auction/ShareModal.vue";
export default {
  name: "CreatorsComponent",
  props: {
    id: Number
  },
  components: {
    ShareModal
  },
  data() {
    return {
      contract: null,
      polygonUrl: null
    };
  },
  computed: {
    getShareModalStatus() {
      return this.$store.getters.getShareModalStatus
    },
    maskTransaction() {
      if(this.contract){
        let strFirst = this.contract.substr(0, 10);
        let strSecond = this.contract.substr(30, 42);
        let maskStr = strFirst + "..." + strSecond;
        return maskStr;
      } else {
        return null
      }
    },
  },
  methods: {
    shareModalStatus() {
      if (this.getShareModalStatus !== 'open') {
        this.$store.commit("setShareModalStatus","open")
      } else {
        this.$store.commit("setShareModalStatus","")
      }
      
    }
  },
  mounted(){
    this.contract = process.env.VUE_APP_CONTRACT_ADDRESS_AUCTION
    this.polygonUrl = process.env.VUE_APP_POLYGON_URL
  }
};
</script>

<style lang="scss" scoped>
.сreators-component {
  padding-bottom: 5rem;
  padding-top: 5rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.creators-item_name {
  color: $white;
  margin-left: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  &--uppercase {
    text-transform: uppercase;
  }
}
.creators-capture {
  color: $white;
  text-align: center;
  margin-bottom: 0.5rem;
}
.creators-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.125rem;
  border: 1px solid #262626;
  border-radius: 52px;
  text-decoration: none;
}
.creators-item_icon {
  width: 2.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.сreators-component_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    flex-direction: column;
    grid-gap: 1rem;
  }
}
.сreators-component_address {
  color: $white;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  a {
    color: $white;
    font-weight: 700;
    padding-left: 0.5rem;
  }
   @media only screen and (min-width: 300px) and (max-width: 1200px) {
     padding: 0 .5rem;
   }
}
.сreators-component_description {
  border-top: 1px solid #262626;
  border-bottom: 1px solid #262626;
  padding: 2rem 0;
  text-align: center;
  max-width: 42rem;
  margin: 0 auto;
  color: $white;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 2rem;
  }
}
.сreators-component_description-title {
  font-size: 2rem;
}
.сreators-component_description-text {
  font-size: 1.125rem;
  color: $gray5;
  margin-top: 0.5rem;
}
.сreators-component_share {
  width: 20%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.button-share {
  background: transparent;
  color: $white;
  border: 1px solid #262626;
  padding: 1rem 1rem 1rem 3rem;
  font-size: 1.125rem;
  font-weight: 700;
  position: relative;
  margin-top: 2rem;
  &::before {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(@/assets/img/icons/share.svg);
    background-repeat: no-repeat;
    width: 3rem;
    height: 0.5rem;
  }
}
</style>