<template>
  <div class="minting-block">
    <div class="minting-block_details">
      <p class="minting-block_details-item">
        Status:<span class="minting-block_details-item-value">{{projectStatus}}</span>
      </p>
      <p class="minting-block_details-item">Minting price:<span class="minting-block_details-item-value">{{$store.getters.getContract.defaultPrice}} ETH</span>
      </p>
      <p class="minting-block_details-item">
        Already mint:<span class="minting-block_details-item-value">{{$store.getters.getContract.mintedNftCount}}/{{$store.getters.getContract.maxValue}}</span>
      </p>
    </div>
    <div class="minting-block_count">
      <h3 class="minting-block_count-title">quantity</h3>
      <p class="minting-block_count-caption">Max {{$store.getters.getContract.maxMintAmount}} NFTs per account</p>
      <div class="minting-block_count-container">
        <button class="minting-block_count-container-button" :class="count == minCount ? 'disabled' : ''" @click="setCount(count-1)">-</button>
        <input type="number" step="1" min="1" v-model="count">
        <button class="minting-block_count-container-button" :class="count == $store.getters.getContract.maxMintAmount ? 'disabled' : ''" @click="setCount(count+1)">+</button>
      </div>
      <p class="minting-block_count-total">Total : {{totalPrice}} ETH </p>
      <button @click="mittingNft" class="minting-block_count-button button button--shadow" :class="count == minCount ? 'disabled' : ''">Minting NFT</button>
    </div>
  </div>
</template>


<script>
export default {
  name: "MintingNft",
  props: {
    projectStatus: String,
  },
  data() {
    return {
      count: 0,
      totalPrice: 0,
      minCount: 0
    }
  },
  emits: ["mint"],
  methods: {
    async setCount(count){
      this.count = count
      this.totalPrice = await this.$store.getters.getContract.getPriceForAmount(count)
    },
    mittingNft() {
      this.$emit("mint", this.count)
    },
  },
};
</script>


<style lang="scss" scoped>
.minting-block_details {
  margin-top: 0.875rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding: 0 .5rem;
  }
}
.minting-block {
  width: 100%;
  transition: .5s ease-out;
}
.minting-block_details-item {
  @include smallText;
  color: $brown;
}
.minting-block_details-item-value {
  text-decoration: underline;
}

.minting-block_count {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.minting-block_count-title {
  @include h3;
  text-shadow: none;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    font-size: 2.875rem;
  }
}
.minting-block_count-caption {
  font-size: 0.75rem;
  color: $brown;
  opacity: 0.6;
}

.minting-block_count-container {
  margin-top: 1.125rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input {
    @include creepyTitle;
    color: $pink;
    text-shadow: none;
    background: transparent;
    border: none;
    outline: none;
    pointer-events: none;
    font-size: 2.875rem;
    width: 5rem;
    height: 3rem;
    text-align: center;
    @media only screen and (min-width: 300px) and (max-width: 1200px) {
      font-size: 2.875rem;
    }
  }
  .minting-block_count-container-button {
    @include h3;
    background: transparent;
    border: 2px solid $dark;
    border-radius: .875rem;
    padding: .5rem 1.6rem;
    cursor: pointer;
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
  
}
.minting-block_count-button {
  @include h3;
  margin-top: 2.5rem;
  background-color: $pink;
  color: $white;
  padding: 2rem 1rem;
  border: 3px solid $text;
  border-radius: .5rem;
  &.disabled {
    opacity: .5;
    pointer-events: none
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
      font-size: 2.875rem;
    }
}
.minting-block_count-total {
  @include creepyTitle;
  color: $pink;
  text-shadow: none;
  font-size: 1.5rem;
  margin-top: 1.25rem;
}
</style>