import Contract from "@/helpers/Contract.js"

export default {
    state: {
        projects: [],
        project: null,
        apiUrl: '',
        postUrl: '',
        currentBtn: null,
        projectContract: null
    },
    getters: {
        getProjects(state) {
            return state.projects
        },
        getCurProject(state) {
            return state.project
        },
        getApiUrl(state) {
            return state.apiUrl
        },
        getPostUrl(state) {
            return state.postUrl
        },
        getCurrentBtn(state) {
            return state.currentBtn
        },
        getCurProjectContract(state) {
            return state.projectContract
        }
    },
    mutations: {
        setProjects(state, projects) {
            state.projects = projects
        },
        setCurProject(state, project) {
            state.project = project
        },
        setApiUrl(state, apiUrl) {
            state.apiUrl = apiUrl
        },
        setPostUrl(state, url) {
            state.postUrl = url
        },
        setCurrentBtn(state, btn) {
            state.currentBtn = btn
        },
        setCurProjectContract(state, contractAddress) {
            state.projectContract = new Contract();
            state.projectContract.init(contractAddress, this);
        },
    },
    actions: {
        async fetch({commit}) {
            let posts = await fetch("https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/projects")
            .then((response) => response.json())
            .then((data) => {
                return data
            })

            commit("setProjects", posts);
        },
        async setCurrentPost({commit}, postSlug){
            let url = "https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/projects/?slug=" + postSlug
            let post = await fetch(url)
            .then((response) => response.json())
            .then((data) => {
                return data[0]
            })
            commit('setCurProject', post)

           // post["contractAddress"] = "0xc5d17d3c96c5b8C3F50a7A6BA4771f81a2b2aA36"
            commit('setCurProjectContract', post.acf.contract_address)

            let apiUrl = "https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/projects/" + post.id
            let postUrl = "https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/projects/" + post.id
            commit('setApiUrl', apiUrl)
            commit('setPostUrl', postUrl)
        }
    }
}