<template>
  <header class="main-header_mobile">
    <a href="/" class="main-header_mobile-logo">
       <img src="@/assets/img/logo.svg" alt="Grave yard">
    </a>
    <button
      class="button main-header_mobile-button"
      @click="openMenu"
      :class="getMenuMobile == 'open' ? 'open' : ''"
    >
      menu
    </button>
    <div
      class="main-header_mobile-menu"
      :class="getMenuMobile == 'open' ? 'open' : ''"
    >
      <button
        v-if="!checkLogin"
        class="button main-header_button button--square button--shadow"
        @click="openModal"
      >
        Connect wallet
      </button>
      <button
        v-else
        style="pointer-events: none;"
        class="button main-header_button button--square button--shadow"
      >
        Wallet connected
      </button>
      <SocialsList
        :main="true"
      />
    </div>
    <img class="cloud cloud--first" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--second" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--third" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--fourth" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--firth" src="@/assets/img/cloud.png" />
    <AppGraveyard/>
  </header>
</template>
<script>
import AppGraveyard from "@/views/AppGraveyard.vue";
import SocialsList from "@/components/ui/SocialsList.vue";
export default {
  name: "MobileHeader",
  components: {
    SocialsList,
    AppGraveyard
  },
  computed: {
    getMenuMobile() {
      return this.$store.getters.getMenuMobile;
    },
    checkLogin() {
      return this.$store.getters.getWallet.wallet.connected;
    },
  },
  methods: {
    openModal() {
      this.$store.commit("updateStatusModal", "open");
      this.$store.commit("setMenuMobile", "");
    },
    openMenu() {
      if (this.getMenuMobile == "open") {
        this.$store.commit("setMenuMobile", "");
      } else {
        this.$store.commit("setMenuMobile", "open");
      }
    },
  },
};
</script>

<style lang="scss">
.main-header_mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem 0.75rem;
  z-index: 8;
  .main-header_mobile-button {
    font-size: 1.5rem;
    z-index: 4;
    &::after,
    &::before {
      background-color: #fff;
      content: "";
      height: 2px;
      opacity: 0;
      position: absolute;
      right: 0.2rem;
      top: calc(50% - 1px);
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      width: 1.2rem;
    }
    &.open {
      background-color: #f969e6;
      color: #fff;
      padding-right: 1.5rem;
      transform: rotate(0deg) scale(1.07);
      &::before {
        opacity: 1;
        transform: scale(1) rotate(45deg);
      }
      &::after {
        opacity: 1;
        transform: scale(1) rotate(-45deg);
      }
    }
  }
  .cloud {
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    animation: cloud 10s linear infinite;
    &--first {
    }
    &--second {
      top: 20%;
      left: 30%;
    }
    &--third {
      top: 20%;
      left: 70%;
    }
    &--fourth {
      top: 20%;
      left: 40%;
      @media only screen and (min-width: 300px) and (max-width: 1200px) {
        display: none;
      }
    }
    &--firth {
      top: 30%;
      left: 60%;
      @media only screen and (min-width: 300px) and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
.main-header_mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50vh;
  background-color: #c4dbe8;
  z-index: 3;
  transition: 0.4s cubic-bezier(0.55, 0, 1, 0.45);
  border-radius: 0 0 0.6rem 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate3d(0, -100%, 0);
  padding-top: 4rem;
  &.open {
    transform: translateZ(0);
  }
  .button {
    font-size: 2.875rem;
    margin-top: 3rem;
    color: #ffffff;
    text-align: center;
    background-color: #f969e6;
    max-width: 14rem;
    min-height: 14rem;
    padding: 1.5rem 1rem;
    background-position: center 20%;
  }
}
.main-header_mobile-logo {
  display: block;
  width: 12rem;
  height: 5rem;
  background-size: 12rem;
  position: relative;
  margin-right: 3rem;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>