<template>
  <div class="bid-block" v-if="currentNFT">
    <h1 class="bid-block_title">{{ currentNFT.name }}</h1>
    <div class="bid-block_current-bid">
      <div class="bid-block_current-bid_head" v-if="maxUserBid !== false">
        <p class="bid-block_current-bid_head-title">Current bid</p>
        <div class="user">
          <span
            class="user-avatar"
            :style="`background: linear-gradient(${maxUserBid.bgColor.colors[0]}, ${maxUserBid.bgColor.colors[1]}`"
          ></span>
          <span class="user-id" v-if="maskCurrentID">{{ maskCurrentID }}</span>
        </div>
      </div>
      <p class="bid-block_current-bid-price">
        {{ maxUserBid !== false ? maxUserBid.bid : maxBid }} ETH
      </p>
      <button
        class="button-bid bid-block_current-bid-button"
        ref="placeBid"
        @click="openBidBlockModal(currentNFT)"
      >
        Place bid
      </button>
    </div>
    <ul class="bid-block_bids">
      <li
        class="bid-block_bids-item"
        v-for="user of sortUsers"
        :key="user.id + Math.random()"
        :class="maxBid == user.bid ? 'max-bid' : ''"
      >
        <div class="bid-block_bids-item_wrap">
          <div class="item-meta">
            <span class="user-avatar" :style="setUserAvatar(user.id)"></span>
            <p class="item-id">Bid placed by {{ maskID(user.id) }}</p>
          </div>

          <span class="item-time">{{ userTime(user.time) }}</span>
        </div>
        <div class="item-meta">
          <p class="item-price">{{ user.bid }} ETH</p>
          <a
            :href="polygonUrl + 'tx/' + user.transactionHash"
            class="item-link"
            target="_blank"
          ></a>
        </div>
      </li>
    </ul>
    <div class="end-of-auc">
      <p class="end-of-auc_text">
        <AuctionStatus :endTime="currentNFT.end_time"/>
        <TimerAuc :endTime="currentNFT.end_time" />
      </p>
    </div>
    <CreatorsComponent />
  </div>
</template>

<script>
import AuctionStatus from "@/components/parts/auction/AuctionStatus.vue";
import Colors from "@/static/colors.json";
import CreatorsComponent from "@/modules/AucModules/CreatorsComponent.vue";
import TimerAuc from "@/components/parts/auction/TimerAuc.vue";
import { maskedFunc } from "@/components/common/masked";
export default {
  name: "BidBlock",
  components: {
    CreatorsComponent,
    TimerAuc,
    AuctionStatus,
  },
  data() {
    return {
      maskedFunc,
      Users: [],
      Colors,
      polygonUrl: null,
      maxUserBid: false,
      sortUsers: [],
      currentTime: (new Date().getTime() / 1000).toFixed(0),
    };
  },
  props: ["currentNFT", "id"],
  computed: {
    maxBid() {
      return this.currentNFT.price;
    },
    maskCurrentID() {
      let maskCurrentID;
      if (this.maxUserBid) {
        maskCurrentID = maskedFunc(this.maxUserBid.id);
      }
      return maskCurrentID;
    },
    getTimeObj() {
      return this.$store.getters.getTimeObj;
    },
    getUserAvatar() {
      return this.$store.getters.getUserAvatar;
    },
  },
  mounted() {
    this.polygonUrl = process.env.VUE_APP_POLYGON_URL;
    this.getBids();
    if (
      this.currentNFT.end_time !== 0 &&
      this.currentNFT.end_time >= this.currentTime
    ) {
      setInterval(() => {
        this.currentTime = (new Date().getTime() / 1000).toFixed(0);
      }, 1000);
    }
    if (this.currentNFT.end_time == 0) {
      this.$refs.placeBid.classList.add("button-bid");
    }
    if (
      this.currentNFT.end_time < this.currentTime &&
      this.currentNFT.end_time !== 0
    ) {
      this.$refs.placeBid.classList.add("button-bid--sold");
    }
  },

  watch: {
    currentNFT(newVal, oldVal) {
      if (newVal.bidcount != oldVal.bidcount) {
        this.getBids();
      }
      this.getBids();
    },
    "currentNFT.bidcount"() {
      this.getBids();
    },
    currentTime() {
      if (this.currentNFT.end_time <= this.currentTime) {
        this.$forceUpdate();
      }
    },
    "currentNFT.end_time"() {
      if (
        this.currentNFT.end_time !== 0 &&
        this.currentNFT.end_time >= this.currentTime
      ) {
        setInterval(() => {
          this.currentTime = (new Date().getTime() / 1000).toFixed(0);
        }, 1000);
      }
      if (this.currentNFT.end_time == 0) {
        this.$refs.placeBid.classList.remove("button-bid--sold");
        // console.log('ready')
      }
      if (
        this.currentNFT.end_time < this.currentTime &&
        this.currentNFT.end_time !== 0
      ) {
        this.$refs.placeBid.classList.add("button-bid--sold");
        // console.log('sold')
      }
    },

    maxUserBid() {
      if (this.maxUserBid !== false) {
        let curId = this.maxUserBid.id;
        let arrayString = curId.split("");
        let indexColor = Number(arrayString[2] + arrayString[3]);
        indexColor = isNaN(indexColor) ? Math.random().toFixed(0) : indexColor;
        let colorsArray = this.Colors;
        this.maxUserBid["bgColor"] = colorsArray[indexColor];
      }
    },
  },
  methods: {
    openBidBlockModal(id) {
      this.$emit("openBidBlockModal", id);
    },
    maskID(id) {
      let maskItem = maskedFunc(id);
      return maskItem;
    },
    setUserAvatar(id) {
      let userId = id;
      let arrayString = userId.split("");
      let indexColor = Number(arrayString[2] + arrayString[3]);
      indexColor = isNaN(indexColor) ? Math.random().toFixed(0) : indexColor
      let colorsArray = this.Colors;
      let style = `background: linear-gradient(${colorsArray[indexColor].colors[0]}, ${colorsArray[indexColor].colors[1]})`;
      return style;
    },
    userTime(time) {
      function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time =
          date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
        return time;
      }
      let newDate = timeConverter(time);
      return newDate;
    },
    getMaxUserBid() {
      let users = [...this.Users];
      let currentUser = {};
      users.forEach((el) => {
        if (this.maxBid == el.bid) {
          currentUser = el;
          // return currentUser;
        }
      });

      return currentUser.id ? currentUser : false;
    },
    getSortUsers() {
      let users = this.Users;
      let sortUsers = users.sort((a, b) => {
        return b.bid - a.bid;
      });
      return sortUsers;
    },
    async getBids() {
      this.Users = [];
      let newUsers = [...this.Users];
      const bids = [];
      for (var i = 1; i <= this.currentNFT.bidcount; i++) {
        bids.push(
          await this.$store.getters.getAuctionContract.getBid(
            this.currentNFT.id,
            i
          )
        );
      }

      const bidsHash = await this.$store.getters.getAuctionContract.getBidsHash(
        this.currentNFT.id
      );
      bids.forEach(function (item) {
        let transactionHash = null;
        let time = null;
        bidsHash.forEach(function (hashItem) {
          if (
            hashItem.returnValues.bidder == item.bidder &&
            hashItem.returnValues.bid_price / Math.pow(10, 18) == item.bid_price
          ) {
            transactionHash = hashItem.transactionHash;
            time = hashItem.returnValues.time;
          }
        });
        let newUser = {
          id: item.bidder,
          bid: item.bid_price,
          transactionHash: transactionHash,
          time: time,
        };
        newUsers.push(newUser);
      });

      this.Users = newUsers;
      this.maxUserBid = this.getMaxUserBid();
      this.sortUsers = this.getSortUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
.bid-block {
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: $auctionBlack;
  position: relative;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    padding-top: 6rem;
  }
}
.bid-block_title {
  text-align: center;
  color: $white;
  @include h1Auc;
  margin-bottom: 1rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    font-size: 2.5rem;
  }
}
.bid-block_current-bid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
  border: 1px solid #222222;
  max-width: 42rem;
  margin: 0 auto;
  padding: 1rem 1rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-bottom: 1.5rem;
    max-width: 22rem;
    .user-avatar {
      position: static;
      width: 1rem;
      height: 1rem;
    }
  }
}
.bid-block_current-bid_head {
  display: flex;
  align-items: center;
}
.bid-block_current-bid_head-title {
  @include noramlTextAuc;
  color: $white;
  font-weight: 700;
}
.user {
  border: 1px solid #222222;
  border-radius: 60px;
  color: $white;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  margin-left: 1rem;
}
.user-avatar {
  width: 1rem;
  height: 1rem;
  //   background-color: $pink;
  display: block;
  border-radius: 100%;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    position: absolute;
    top: 1rem;
    left: 2rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}
.user-id {
  margin-left: 1rem;
}
.bid-block_current-bid-price {
  font-size: 3rem;
  color: $white;
  margin-top: 0.5rem;
}
.bid-block_current-bid-button {
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 15rem;
    transform: translateY(-50%) rotate(45deg);
    width: 0.5rem;
    height: 0.5rem;
    background-color: $green;
    animation: live 1s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &:disabled {
    &::after {
      content: "";
      background-color: $orange;
  }
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    &::after {
      left: 5rem;
    }
  }
  &.button-bid--sold {
    pointer-events: none;
    background-color: $gray;
    &::after {
      background-color: $red;
      animation: none;
    }
  }
}
.bid-block_bids-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  background-color: $black;
  margin-top: 0.5rem;
  width: 100%;
  padding: 1rem;
  &:not(.max-bid) {
    opacity: 0.5;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    position: relative;
    padding: 1rem 1rem 1rem 5rem;
  }
}
.bid-block_bids {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 42rem;
  margin: 0 auto;
  max-height: 400px;
  overflow-y: scroll;
}
.item-price {
  @include largeTextAuc;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    font-size: 1.5rem;
  }
}
.item-id {
  @include bodyTextAuction;
  margin-left: 0.5rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-left: 0rem;
  }
}
.item-time {
  @include smallText;
  opacity: 0.5;
  margin-top: 0.25rem;
  display: inline-block;
}
.item-avatar {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  border-radius: 100%;
}
.item-meta {
  display: flex;
  align-items: center;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    justify-content: space-between;
    width: 100%;
  }
}
.item-link {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(@/assets/img/icons/export.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: block;
  margin-left: 1rem;
}
.end-of-auc {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f8f8f8;
  padding: 0.7rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    width: 100%;
    text-align: center;
  }
}
.end-of-auc_text {
  font-weight: 700;
  @include noramlTextAuc;
  position: relative;
  padding-left: 0rem;
  display: flex;
  align-items: center;
  color: $black;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   left: 0;
  //   transform: translateY(-50%) rotate(45deg);
  //   width: 0.5rem;
  //   height: 0.5rem;
  //   animation: live 1s infinite cubic-bezier(0.175, 0.885, 0.32, 1.275);
  //   background-color: $green;
  // }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    justify-content: center;
  }
}
</style>