<template>
  <div class="share-modal" :class="getShareModalStatus == 'open' ? 'open' : ''">
    <button class="share-modal_copy" @click="copyLink">Copy link</button>
    <ShareNetwork
      network="twitter"
      :url="currentLink"
      title="Twelwe monkeys collection"
      description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
      quote="The hot reload is so fast it\'s near instant. - Evan You"
      hashtags="vuejs,vite"
    >
      Twitter
    </ShareNetwork>
  </div>
  
</template>
<script>
import { copyFunction } from "@/components/common/copy-function.js";
export default {
  data() {
    return {
      copyFunction,
    };
  },
  computed: {
    getShareModalStatus() {
      return this.$store.getters.getShareModalStatus;
    },
    getCurrentPath() {
      return this.$store.getters.getCurrentPath;
    },
    currentLink() {
      let link;
      if (this.getCurrentPath == "") {
        link = window.location.origin + this.$route.path;
      } else {
        link = window.location.origin + this.getCurrentPath;
      }

      return link;
    },
  },
  
  methods: {
    copyLink(event) {
      copyFunction(event, this.currentLink);
    },
  },
};
</script>

<style lang="scss">
.share-modal {
  background-color: $black;
  padding: 1rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 1rem;
  left: 0;
  border-radius: 16px;
  transition: .3s cubic-bezier(0.215, 0.610, 0.355, 1);
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
      left: -8rem;
  }
  &.open {
    opacity: 1;
    pointer-events: all;

  }
  a {
    margin-top: 1rem;
    color: $white;
    text-decoration: none;
    display: block;
    padding-left: 1.5rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -0.5rem;
      transform: translateY(-50%);
      width: 1.5rem;
      height: 1.5rem;
      background-image: url(@/assets/img/icons/Twitter.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}
.share-modal_copy {
  position: relative;
  background-color: transparent;
  color: $white;
  padding-left: 1.5rem;
  &::after {
    content: "";
    position: absolute;
    left: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(@/assets/img/icons/copylink.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>