<template>
  <ModalHead tabs="true" @closeModal="closeModal">
    <div class="tabs">
      <a
        href="#"
        @click.prevent="changeTab(tab.name)"
        v-for="tab of tabs"
        :key="tab.name"
        class="tab"
        :class="activeTab == tab.name ? 'active' : ''"
        ><h2>{{ tab.name }}</h2></a
      >
    </div>
  </ModalHead>
  <div class="content-wrapper">
    <InputText
      placeholder="Wallet number or Name"
      :type="'search'"
      style="max-width: 38.375rem"
      @setValue="filterWallets"
    >
    </InputText>
    <div class="content-wrapper scroll-content">
      <div class="wallet-row fixed">
        <div class="index"></div>
        <InputText
          onlyLabels="true"
          value="0xD342FFb241963FE0881a60117-----------------4"
        >
          Name
        </InputText>
        <InputText onlyLabels="true" style="flex-grow: 1"> Wallet </InputText>
        <InputNumber onlyLabels="true">Mint quantity</InputNumber>
        <div style="min-width: 2.5rem; min-height: 2.5rem"></div>
      </div>
      <div
        v-for="(wallet, index) of changed"
        :key="wallet.wallet + String(Math.random())"
        :data-wallet="wallet.wallet"
        class="wallet-row"
      >
        <div class="index">{{ index + 1 }}</div>
        <InputText :value="wallet.name" @setValue="setValue" name="name">
        </InputText>
        <InputText
          :value="wallet.wallet"
          @setValue="setValue"
          style="flex-grow: 1"
          name="wallet"
        >
        </InputText>
        <InputNumber
          v-if="activeTab !== 'Waitlist'"
          :value="wallet.mint_quantity"
          :controls="true"
          @setValue="setValue"
          name="mint_quantity"
        ></InputNumber>
        <ButtonDelete @click="deleteWallet(wallet.wallet)" />
      </div>
    </div>

    <div class="bottom-buttons">
      <ButtonUi style="width: max-content" buttonClass="gray" @click="addWallet"
        >+ Wallet</ButtonUi
      >
      <ButtonUi
        v-if="activeTab !== 'Owners'"
        style="width: max-content"
        buttonClass="transparent"
        @click="triggerFile"
        >Upload CSV
      </ButtonUi>
      <input type="file" hidden data-csv-file />
    </div>
  </div>

  <div class="buttons">
    <ButtonUi :disabled="JSON.stringify(changed) == JSON.stringify(wallets)" buttonClass="green"  @click="sendRequest"
      >Update</ButtonUi
    >
  </div>
</template>

<script>
import ModalHead from "@/components/modals/project/ModalHead.vue";
import InputText from "@/components/ui/InputText.vue";
import InputNumber from "@/components/ui/InputNumber.vue";
import ButtonUi from "@/components/ui/ButtonUi.vue";
import ButtonDelete from "@/components/ui/ButtonDelete.vue";
import Papa from "papaparse";

export default {
  emits: ["closeModal", "sendData"],
  data() {
    return {
      value: "",
      tabs: [
        {
          name: "Owners",
        },
        {
          name: "Giveaway",
        },
        {
          name: "Waitlist",
        },
      ],
      activeTab: "Owners",
      changed: [],
      filtered: [],
    };
  },

  props: ["props"],

  components: {
    ModalHead,
    InputText,
    ButtonUi,
    ButtonDelete,
    InputNumber,
  },

  computed: {
    wallets() {
      return this.activeTab == "Owners"
        ? this.props.owners_list
        : this.activeTab == "Giveaway"
        ? this.props.giveaway_list
        : this.activeTab == "Waitlist"
        ? this.props.waitlist_list
        : [];
    },
  },

  methods: {
    closeModal(status) {
      this.$emit("closeModal", status);
    },

    addWallet() {
      let newWallet = {
        name: "",
        wallet: "",
        mint_quantity: "0",
      };
      this.changed.push(newWallet);
    },

    deleteWallet(number) {
      this.changed = this.changed.filter((el) => el.wallet != number);
      if (this.changed.length == 0) {
        this.addWallet();
      }
    },

    setValue(evt, value, name) {
      let wallets = [...this.changed];
      let index = 0;
      let attr = evt.target.closest(".wallet-row").dataset.wallet;
      let curWallet = wallets.find((el, i) => {
        index = i;
        return el.wallet == attr;
      });
      curWallet[name] = value;
      wallets.splice(index, 1, curWallet);
    },

    triggerFile() {
      let input = document.querySelector("[data-csv-file]");
      input.click();
    },

    filterWallets(evt, value) {
      value = value.toLowerCase();
      let wallets = [...this.changed];
      this.$store.commit("setWallets", this.filtered);
      if (value !== "" && value !== " ") {
        wallets = wallets.filter(
          (el) =>
            el.wallet.toLowerCase().includes(value) ||
            el.name.toLowerCase().includes(value)
        );
        this.changed =
          wallets.length > 0 ? wallets : this.$store.getters.getWallets
      } else {
        this.changed = this.$store.getters.getWallets;
      }
    },

    changeTab(name) {
      this.activeTab = name;
      if (!this.wallets){
        this.changed = [];
        this.addWallet()
      } else{
        this.changed = [...this.wallets];
      }
    },

    updateList() {
      let oldList = [...this.wallets];
      let newList = [...this.changed];

      // Элементы для удаления из массива
      let listToDelete = oldList.filter((wallet) =>
        newList.every(
          (item) =>
            JSON.stringify(item).replace(/\s+/g, "") !==
            JSON.stringify(wallet).replace(/\s+/g, "")
        )
      ); 
      

      // Получаем новые элементы и удаляем из них пустые
      let listToAdd = newList.filter((wallet) =>
        oldList.every(
          (item) =>
            JSON.stringify(item).replace(/\s+/g, "") !==
              JSON.stringify(wallet).replace(/\s+/g, "") &&
            wallet.number !== "" &&
            wallet.name !== "" &&
            wallet.percent !== ""
        )
      ); 

      // Удаляем дубликаты
      listToAdd = [
        ...Array.from(new Set(listToAdd.map(JSON.stringify))).map(JSON.parse),
      ]; 

      // Удаляем объекты с одинаковыми кошельками
      listToAdd = listToAdd.filter(
        (
          (set) => (wallet) =>
            !set.has(wallet.number) && set.add(wallet.number)
        )(new Set())
      ); 
      console.log(listToDelete, listToAdd);
    },

    sendRequest(evt) {
        const fieldName = `${this.activeTab.toLowerCase()}_list`
        let dataObj = {}
        dataObj[fieldName] = this.changed
        let data = {
            "acf": dataObj
        }
        this.$store.commit('setCurrentBtn', evt.target)
        this.$emit('sendData', data)

        if(dataObj.owners_list){
          let addresses = [];
          let counts = [];

          dataObj.owners_list.forEach(function(el){
            addresses.push(el.wallet)
            counts.push(parseInt(el.mint_quantity))
          })

          this.$store.getters.getCurProjectContract.addOwnersUsers(addresses, counts)
        }

        if(dataObj.giveaway_list){
          let addresses = [];
          let counts = [];

          dataObj.giveaway_list.forEach(function(el){
            addresses.push(el.wallet)
            counts.push(parseInt(el.mint_quantity))
          })

          this.$store.getters.getCurProjectContract.addGiveawayUsers(addresses, counts)
        }

        if(dataObj.waitlist_list){
          let addresses = [];

          dataObj.waitlist_list.forEach(function(el){
            addresses.push(el.wallet)
          })

          this.$store.getters.getCurProjectContract.addWhitelistedUsers(addresses)
        }
    },
  },

  mounted() {
    if(!this.wallets){
      this.addWallet()
    } else{
      this.changed = [...this.wallets];
    }
    
    this.filtered = [...this.changed];

    let completeFn = (results) => {
      console.log(" Results:", results);
      if (results.data.length !== 0) {
        this.changed = results.data;
      }
    };

    let input = document.querySelector("[data-csv-file]");
    let options = {
      delimiter: "", // auto-detect
      newline: "", // auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: true,
      transformHeader: undefined,
      dynamicTyping: false,
      preview: 0,
      encoding: "",
      worker: false,
      comments: false,
      step: undefined,
      complete: completeFn,
      error: undefined,
      download: false,
      downloadRequestHeaders: undefined,
      downloadRequestBody: undefined,
      skipEmptyLines: false,
      chunk: undefined,
      chunkSize: undefined,
      fastMode: undefined,
      beforeFirstChunk: undefined,
      withCredentials: undefined,
      transform: undefined,
      delimitersToGuess: [",", "\t", "|", ";", Papa.RECORD_SEP, Papa.UNIT_SEP],
    };

    input.addEventListener("change", () => {
      Papa.parse(input.files[0], options);
      input.value = "";
    });
  },
};
</script>

<style scoped lang="scss">
h2 {
  font: inherit;
}

.tabs {
  display: flex;
}

.tab {
  margin-right: 3rem;
  color: $white;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: 0.1s ease-out;

  &:hover {
    border-bottom: 1px solid $white;
  }

  &.active {
    border-bottom: 1px solid $white;
    opacity: 0.5;
  }
}

.content-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.25rem;
  padding: 1.75rem 0;
  border-bottom: 1px solid $darkBlue;
  overflow: hidden;

  &.row {
    display: flex;
    justify-content: flex-start;
    & > * {
      margin-right: 1.75rem;
      width: max-content;
    }
  }

  &.scroll-content {
    max-height: 38vh;
    overflow: scroll;
    border-bottom: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    margin-top: 0.5rem;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.bottom-buttons {
  margin-top: -0.25rem;
  position: relative;
  box-shadow: 0 -1rem 2rem 1rem $black;
  display: flex;

  button {
    margin-right: 1.5rem;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.75rem;
}

.wallet-row {
  display: flex;
  align-items: flex-end;

  & > *:not(:last-child) {
    margin-right: 1.5rem;
  }

  label {
    width: max-content;
  }

  &.fixed {
    position: fixed;
    z-index: 2;
    width: calc(100% - 3rem);
    margin-top: -1.25rem;
    background-color: $black;
    max-height: 1.25rem;
    align-items: flex-start;
    pointer-events: none;
  }
}

.index {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 1.125rem;
  line-height: 1.35rem;
  font-weight: 500;
  height: 100%;
  min-width: 2rem;
  padding-top: 0.5rem;
}
</style>

