<template>
  <div class="table-loop">
    <div v-for="item of arrayNft" :key="item.id + Math.random()" class="table-loop_item">
      <div class="table-loop_item-image">
        <img  :width="getWebVersion == 'mobile' ? 112 : 85" :height="getWebVersion == 'mobile' ? 112 : 96" :src="item.path" :alt="item.name"/>
      </div>
      <div class="table-loop_item-desc">
        <div class="table-loop_item-desc-column">
          <p class="title-nft">{{ item.name }}</p>
          <p class="price-nft">{{ item.price }} ETH</p>
        </div>
        <div class="table-loop_item-desc-column">
          <div class="table-loop_item-desc-wrap">
            <AuctionStatus :endTime="item.end_time"/>
            <p class="time"><TimerAuc :endTime="item.end_time"/></p>
          </div>
          <button type="button" class="button-bid" @click="openBidModal(item)" :disabled="item.end_time < currentTime && item.end_time !== 0">Place bid</button>
          <a href="#" @click.prevent="pushToSinglePage(item.id)" class="table-loop_link"
            >open</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuctionStatus from "@/components/parts/auction/AuctionStatus.vue";
import TimerAuc from "@/components/parts/auction/TimerAuc.vue"
export default {
  name: "TableLoop",
  components: {
    TimerAuc,
    AuctionStatus
  },
  computed: {
    arrayNft() {
      return this.$store.getters.getNft;
    },
    currentTime() {
      let currentTime = new Date().getTime() / 1000;
      return currentTime.toFixed(0);
    },
    getWebVersion() {
      return this.$store.getters.getWebVersion;
    },
  },
  methods: {
    pushToSinglePage(id) {
      this.$emit("pushToSinglePage", id)
    },
    openBidModal(id) {
      this.$emit("openBidModal", id)
    },
  },
};
</script>

<style lang="scss">
.table-loop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem 2rem;
  margin-top: 3rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.button-bid {
  &:disabled {
    pointer-events: none;
    background-color: $gray;
  }
  
}

.table-loop_wrap-mobile {
  color: $white;
  .title-nft {
    padding: .75rem;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    .title-nft {
      max-width: 7rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.table-loop_link {
  color: #ececec;
  text-decoration: none;
  font-size: 1.125rem;
  margin-left: 1.5rem;
  cursor: pointer;
}
.table-loop_item-desc-wrap {
  margin-right: 2.5rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: flex;
    align-items: center;
    margin-right: 0rem;
  }
}

.table-loop_item-desc-column {
  &:last-of-type {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    &:not(:first-of-type) {
      margin-top: 1.5rem;
    }
  }
}
.table-loop_item-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: $white;
  padding: 0 1rem;
  .price-nft {
    margin-top: 0.2rem;
  }
  .time {
    margin-top: 0.5rem;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: block;
    padding: 0 2rem;
    .price-nft {
      margin-top: 0rem;
    }
    .time {
      margin-top: 0rem;
    }
  }
}

.table-loop_item {
  display: flex;
  align-items: center;
  background-color: $auctionBlack;
}

.table-loop_item-image {
  width: 7rem;
  height: 7rem;
  min-width: 7rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>