<template>
    <div class="network-error">
        <p class="network-error_title">No Internet!</p>
        <p class="network-error_text">Poor network connection detected.</p>
        <p class="network-error_text">Please check your connectivity.</p>
    </div>
</template>

<script>
export default {
    name: "NetworkConection"
}
</script>

<style lang="scss">
    .network-error {
        margin-top: 2.5rem;
        color: $red;
        @include bodyTextAuction;
    }
    .network-error_title {
        font-weight: 700;
        margin-bottom: .5rem;
    }
    .network-error_text {
        font-weight: 500;
    }
</style>