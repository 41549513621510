<template>
    <div class="project-head">
        <h1>{{title}}</h1>
        <button type="button" class="settings" @click="actions = !actions"><span class="dot"></span></button>

        <div v-if="actions" class="actions">
            <button type="button" class="action-button" @click="setName('settings')">Settings</button>
            <button type="button" class="action-button" @click="setName('payments')">Payments</button>
            <button type="button" class="action-button" @click="setName('access')">WL & Giveaway</button>
        </div>
    </div>
</template>

<script>

export default ({
    props: [
        'title',
    ],
    data() {
        return{
            actions: false,
        }
    },

    methods: {
        setName(name){
            this.$emit('setName', name)
            this.actions = false
        }
    },
})
</script>

<style scoped lang="scss">
    .project-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    h1{
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: bold;
        color: $white;
    }

    .settings{
        position: relative;
        padding: .625rem;
        width: 23px;
        height: 23px;
        background-color: transparent;
        margin-left: 5.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dot {
            display: block;
            width: 3px;
            min-width: 3px;
            height: 3px;
            min-height: 3px;
            background-color: $white;
            border-radius: 100%;
        }

        &::before,
        &::after{
            width: 3px;
            min-width: 3px;
            height: 3px;
            min-height: 3px;
            background-color: $white;
            border-radius: 100%;
            position: absolute;
            left: calc(50% - 1.5px);
            content: "";
            box-sizing: border-box;
        }

        &::before{
            top: 0;
        }

        &::after{
            bottom: 0;
        }
    }

    .actions{
        padding-top: .375rem;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 1;
        background-color: $black;
    }

    .action-button{
        padding: .375rem;
        color: $white;
        background-color: transparent;
        font-size: .875rem;
        line-height: 1rem;
    }
</style>
