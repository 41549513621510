<template>
  <section
    class="auction"
    :class="
      getModalStatus == 'open' || getSignatureModalStatus == 'open'
        ? 'auction--overflow'
        : ''
    "
  >
    <div class="intro-block">
      <div class="intro-image">
        <img src="@/assets/img/auction-intro.jpg">
      </div>
      <a href="https://opensea.io/collection/twelveapes" class="button-bid intro-link">View collection</a>
    </div>
    <!-- <SliderBlock v-if="getWebVersion !== 'mobile'" /> -->
    <ImageLoop @openBidModal="openBidModal" @pushToSinglePage="pushToSinglePage"/>
    <CreatorsComponent />
    <BidModal  v-if="currentNFTModal !== null" :currentNFT="currentNFTModal" @closeModal="currentNFTModal = null" />
  </section>
</template>

<script>
//import SliderBlock from "@/modules/AucModules/SliderBlock.vue";
import ImageLoop from "@/modules/AucModules/ImageLoop.vue";
import CreatorsComponent from "@/modules/AucModules/CreatorsComponent.vue";
import BidModal from "@/components/modals/auction/BidModal.vue";
export default {
  name: "AucPage",
  data() {
    return {
      currentId: null,
      currentNFTModal: null
    };
  },
  components: {
    //SliderBlock,
    ImageLoop,
    CreatorsComponent,
    BidModal,
  },
  computed: {
    arrayNft() {
      return this.$store.getters.getNft;
    },
    getWebVersion() {
      return this.$store.getters.getWebVersion;
    },
    getModalStatus() {
      return this.$store.getters.getModalStatus;
    },
    getSignatureModalStatus() {
      return this.$store.getters.getSignatureModalStatus;
    },
    currentNFT() {
      let currentNft = this.arrayNft.find((item) => item.id == this.currentId);
      return currentNft;
    },
  },
  watch: {
    getModalStatus(newVal) {
      console.log('jhvjhvh')
        if (newVal == 'open'){
            document.querySelector('body').style.overflow = 'hidden'
        } else{
            document.querySelector('body').style.overflow = 'unset'
        }
    },
  },
  methods: {
    openBidModal(id) {
      this.currentNFTModal = id
      this.currentId = id;
      this.$store.commit("setModalStatus", "open");
    },
    pushToSinglePage(id) {
      window.scrollTo(0, 0)
      this.$router.push({ path: `/12apes/${id}#` });
      
    }
  },
};
</script>
<style lang="scss">
.auction {
  background-color: $black;
  .intro-block{
    height: 75vh;
    position: relative;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }

  .intro-image{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: auto;
      height: 100%;
    }
  }

  .intro-link{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.125rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 1.5rem 1rem;
    padding-right: 4rem;

    &::before{
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      height: 1.75rem;
      width: 1.875rem;
      transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      background: url('@/assets/img/icons/opensea-dark.svg') center center no-repeat;
      background-size: 100%;
    }

    &:hover{
      background-color: transparent;

      &::before{
        background-image: url('@/assets/img/icons/opensea-white.svg');
      }
    }
  }
  &--overflow {
    // overflow: hidden;
    // height: 100%;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    .intro-link{
      white-space: nowrap;
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: 900;
      padding: 1.625rem 1rem;
      padding-right: 4rem;

      &::before{
        width: 2rem;
        height: 2rem;
      }
    }

    .intro-block{
      padding: 0;
      margin-bottom: 0;
      height: 55vh;
    }

    .intro-image {
      padding-bottom: 7rem;
      img{
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>