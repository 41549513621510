<template>
    <label>
        <slot />
        <div :class="staticValue && controls ? 'static controls' : controls ? 'controls' : staticValue ? 'static' : onlyLabels ? 'only-labels' : ''" >
            <span v-if="staticValue && staticValue !== ''" class="static-value">{{staticValue}}</span>
            <input :name="name" type="text" :placeholder="placeholder" v-model="curValue" @change="changeInput">
            <div v-show="controls" class="buttons">
                <button :class="Number(curValue) >= 99 && staticValue == '%' ? 'disabled' : ''" type="button" class="up control" @click="(event) => controlClick('+', event)"></button>
                <button :class="Number(curValue) <= 1 ? 'disabled' : '' " type="button" class="down control" @click="(event) => controlClick('-', event)"></button>
            </div>
        </div>
    </label>
</template>

<script>

export default ({
    data() {
        return{
            curValue: this.value ? this.value : '' 
        }
    },
    props: [
       'placeholder',
       'value',
       'staticValue',
       'controls',
       'onlyLabels',
       'name'
    ],

    watch: {
        curValue(newVal, oldVal){
            if (this.staticValue == '%' && (!(/^\d*(?:\.\d{0,1})?$/g).test(newVal) || Number(this.curValue) > 100)){
                if (!String(newVal).includes('.') && Number(this.curValue) > 100){
                    let valArray = Array.from(newVal)
                    valArray.splice(2, 0, '.')
                    this.curValue = valArray.join('')
                } else{
                    this.curValue = oldVal
                }
            } else if (this.staticValue !== '%' && newVal != 0){
                let re = /[^\d^\.]/g; //eslint-disable-line
                this.curValue = re.test(newVal) ? newVal.replace(re,'') : newVal
            } else if(newVal == 0){
                this.curValue = newVal
            } 
        }
    },

    methods: {
        changeInput(evt){
            this.curValue = this.curValue.length == 0 ? '0' : this.curValue;
            this.$emit('setValue', evt, this.curValue, this.name)
        },

        controlClick(operator, evt){
           this.curValue =  operator == '+' ? Number(this.curValue) + 1 : Number(this.curValue) - 1
           this.changeInput(evt)
        }
    }
})
</script>

<style scoped lang="scss">
    label {
        font-size: .875rem;
        line-height: 1rem;
        color: $white;
        width: 100%;
        position: relative;

        input{
            display: block;
            margin-top: .5rem;
            width: 100%;
            border: 1px solid $gray3;
            border-radius: .25rem;
            background-color: $darkBlue;
            padding: .625rem;
            color: $white;
            font-size: 1.185rem;
            line-height: 1.35rem;
            font-weight: 500;
            max-width: 5.5rem;
            min-width: 5.5rem;
            transition: .1s ease-out;

            &:hover{
                border-color: $white;
            }

            &::placeholder{
                color: $gray3;
            }

            &:focus{
                outline: none;
                background-color: $black;
                border-color: $gray3;
            }
        }
    }

    .static{
        display: flex;
        align-items: flex-end;
        flex-direction: row-reverse;
        input{
            flex-grow: 1;
            border-radius: .25rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .static-value{
        background-color: transparent;
        border: 1px solid $gray3;
        border-left: none;
        border-radius: .25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: .625rem;
        color: $gray2;
        font-size: 1.185rem;
        line-height: 1.35rem;
        font-weight: 500;
        margin-top: .5rem;
    }

    .controls{
        position: relative;

        input{
            padding-right: 1.875rem;
        }

        .buttons{
            position: absolute;
            bottom: 0;
            //height: calc(100% - .625rem);
            width: 1.875rem;
            left: 3.625rem;
        }
    }

    .control{
        padding: .625rem .625rem .25rem;
        display: block;
        position: relative;
        width: .625rem;
        height: .5rem;
        box-sizing: content-box;
        background-color: transparent;

        &::before{
            position: absolute;
            content: "";
            bottom: .25rem;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border: .25rem solid $lightGray;
            border-top-width: 0;
            border-bottom-width: .375rem;

            border-left-color: transparent;
            border-right-color: transparent;
        }

        &.down{
            transform: rotate(180deg);
        }

        &:hover::before{
            border-bottom-color: $white;
        }

        &.disabled{
            opacity: .7;
            pointer-events: none;
        }
    }

    .only-labels{
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }

</style>
