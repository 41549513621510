<template>
    <button type="button" class="delete" aria-label="delete">
        <img src="@/assets/img/icons/delete.svg">
    </button>
</template>

<style scoped lang="scss">
    .delete{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .625rem;
        border: 1px solid $gray3;
        border-radius: .375rem;
        background-color: $darkBlue;
        min-width: 2.5rem;
        min-height: 2.8rem;
        transition: .1s ease-out;

        &:hover{
            border-color: $white;
        }
    }
</style>
    