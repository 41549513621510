<template>
    <div>
        <AdminHeader>
            <ProjectHead 
                :title="title"
                @setName="setName"
            />
        </AdminHeader>

        <ProjectGallery v-if="ipfsLink !== '' && totalNft !== 0"
            :link="ipfsLink"
            :nft="totalNft"
        />
        
        <ProjectModal 
            v-if="modalStatus"
            :name="modalName"
            :post="post"
            @closeModal="closeModal"
        />
    </div>
</template>

<script>
import AdminHeader from '@/components/headers/AdminHeader.vue'
import ProjectHead from '@/components/headers/ProjectHead.vue'
import ProjectModal from '@/components/modals/ProjectModal.vue'
import ProjectGallery from '@/components/ProjectGallery.vue'
import { useRoute } from 'vue-router'

export default ({
    emits: ["closeModal"],
    data() {
        return {
            modalStatus: false,
            modalName: '',
            title: '',
            ipfsLink: '',
            totalNft: ''
        }
    },

    computed: {
        post(){
            return this.$store.getters.getCurProject 
        },
    },

    watch: {
        modalStatus() {
            if (this.modalStatus){
                document.querySelector('body').style.overflow = 'hidden'
            } else{
                document.querySelector('body').style.overflow = 'unset'
            }
        },

        post(newPost) {
            this.title = newPost.acf.title
        }
    },

    components: {
        AdminHeader,
        ProjectHead,
        ProjectModal,
        ProjectGallery,
    },

    methods: {
        setName(name){
            this.modalName = name
            this.modalStatus = true
        },
        closeModal(status){
            this.modalStatus = status
        }
    
    },

    async created(){
        const route = useRoute()
        let slug = route.params.slug
        this.$store.dispatch("setCurrentPost", slug);
        let url = "https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/projects/?slug=" + slug
        let post = await fetch(url)
            .then((response) => response.json())
            .then((data) => {
                return data[0]
            })
        this.$store.commit('setCurProject', post)
        this.title = post.acf.title ? post.acf.title : "Untitled Project"
        this.ipfsLink = post.acf.ipfs_link ? post.acf.ipfs_link : ''
        this.totalNft = post.acf.nft_total ? post.acf.nft_total : 0
        
    },
})

</script>

<style lang="scss" scoped>
    h1{
        color: $white;
        font-size: 3rem;
        line-height: 3.5rem; 
        font-weight: 400;
    }

    .sticky-button{
        bottom: 1.375rem;
        position: sticky;
        padding-top: 1.5rem;
        padding-left: 1rem;
    }
</style>
