<template>
  <header class="main-header">
    <SocialsList
      :main="true"
    />
    <a href="/" class="main-header_logo">
      <img src="@/assets/img/logo.svg" alt="Grave yard">
    </a>
    <button v-if="!checkLogin" class="button main-header_button" @click="openModal">
      Connect wallet
    </button>
    <button v-else style="pointer-events: none;" class="button main-header_button pink">
      Wallet connected
    </button>
    <img class="cloud cloud--first" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--second" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--third" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--fourth" src="@/assets/img/cloud.png" />
    <img class="cloud cloud--firth" src="@/assets/img/cloud.png" />
    <AppGraveyard/>
  </header>
</template>
<script>
import AppGraveyard from "@/views/AppGraveyard.vue";
import SocialsList from "@/components/ui/SocialsList.vue";
export default {
  name: "DesktopHeader",
  components: {
    SocialsList,
    AppGraveyard
  },
  computed: {
    checkLogin() {
      return this.$store.getters.getWallet.wallet.connected;
    },
  },
  methods: {
    openModal() {
      this.$store.commit("updateStatusModal", "open");
    },
  },
};
</script>

<style lang="scss">


.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem 0.75rem;
  z-index: 20;
  pointer-events: none;
  // & > * {
  //   width: 100%;
  // }
  
  .main-header_button {
    font-size: 2.5rem;
    position: relative;
    z-index: 2;
    pointer-events: all;
    @media only screen and (min-width: 300px) and (max-width: 1200px) {
      position: relative;
      z-index: 9;
    }

    &.pink{
      color: white;
      background-color: #f969e6;
    }
  }
}

.main-header_logo {
  img {
    width: 100%;
    height: 100%;
  }
  display: block;
  height: 9rem;
  width: 30rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1rem;
  z-index: 2;
  pointer-events: all;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    display: none;
  }
}
.cloud {
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  animation: cloud 30s linear infinite;
  &--first {
  }
  &--second {
    top: 20%;
    left: 30%;
  }
  &--third {
    top: 20%;
    left: 70%;
  }
  &--fourth {
    top: 20%;
    left: 40%;
    @media only screen and (min-width: 300px) and (max-width: 1200px) {
      display: none;
    }
  }
  &--firth {
    top: 30%;
    left: 60%;
    @media only screen and (min-width: 300px) and (max-width: 1200px) {
      display: none;
    }
  }
}
@keyframes cloud {
  0% {
    transform: translateX(80vw);
  }
  100% {
    transform: translateX(-80vw);
  }
}
</style>