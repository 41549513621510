<template>
    <router-view v-slot="{ Component }" class="wrapper">
        <component :is="Component"></component>
    </router-view>
</template>


<script>
export default {
  name: "AppLayoutAdmin",
};
</script>

<style lang="scss" scoped>
    .wrapper{
        background-color: $black;
        min-height: 100vh;
    }
</style>