<template>
    <div class="balance-error">
        <p class="balance-error_title">Error! You don’t have enough ETH in your wallet. </p>
    </div>
</template>

<script>
export default {
    name: "BalanceError"
}
</script>

<style lang="scss">
    .balance-error {
        color: $red;
        @include bodyTextAuction;
        margin-bottom: 2.5rem;
    }
    .balance-error_title {
        font-weight: 700;
    }
</style>