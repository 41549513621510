<template>
  <div class="timer" v-if="loaded">
    {{ displayHours }}h {{ displayMinutes }}m
    {{ displaySeconds }}s
  </div>
</template>

<script>
export default {
  props: {
    endTime: Number
  },
  data() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      loaded: true,
    };
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
  },
  mounted() {
    if(this.endTime != 0){
      this.showRemaining();
    } else{
      this.displayMinutes = 0;
      this.displaySeconds = 0;
      this.displayHours = 0;
      this.displayDays = 0;
    }
  },

  watch: {
    endTime(){
      this.showRemaining();
    }
  },

  methods: {
    formatNumb(num) {
      return num < 10 ? `0${num}` : num;
    },
    showRemaining() {
      const timer = setInterval(() => {
        //console.log(this.endTime)
        const now = new Date();
        const end = new Date(this.endTime * 1000);
        const distance = end.getTime() - now.getTime();
        if (distance <= 0) {
          clearInterval(timer);
          return;
        } else {
          const days = Math.floor(distance / this._days);
        const hours = Math.floor(((distance % this._days) / this._hours) + days * 24);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayMinutes = this.formatNumb(minutes);
        this.displaySeconds = this.formatNumb(seconds);
        this.displayHours = this.formatNumb(hours);
        this.displayDays = this.formatNumb(days);
        this.loaded = true;
        }

        
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.timer {
  margin-left: 0.5rem;
}
</style>