// @ts-nocheck
import Web3 from "web3";
import detectEthereumProvider from '@metamask/detect-provider';
import {
  animateState
} from '@/components/common/animation-states';

export default class Wallet {
  typeConnect = "metamask";
  loaded = false
  type = false

  wallet = {
    connected: null,
    chainId: null,
    accounts: [],
    address: "",
    fetching: false,
    pendingRequest: false,
    balance: 0
  }

  connector = null
  web3 = null
  store = null

  onSetAccount = function () {}
  onSetChain = function () {}

  constructor(type = "") {
    this.type = type
  }

  //Проверка подключения кошелька при открытии страницы
  async checkConnect(store = null) {
    if (localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')) {
      const checkMetamaskConnected = await this.checkMetamaskConnected(store);
      if (checkMetamaskConnected && localStorage.getItem('WEB3_TYPE_CONNECT') == "metamask") {
        await this.metamaskConnect();
      } else {
        this.disconnect();
      }
    } else {
      //localStorage.removeItem('nonce');
      this.store.commit("setUserWpAuth", false)
      this.wallet.connected = false
    }

    this.loaded = true
  }

  //Проверка, подключен ли кошелек метамаска
  async checkMetamaskConnected(store = null) {
    if (await this.metamaskEnabled(store)) {
      const accounts = await this.web3.eth.getAccounts();
      if (accounts.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  //Проверка, доступен ли метамаск
  async metamaskEnabled(store = null) {
    if(store){
      this.store = store;
    }
    const provider = await detectEthereumProvider({timeout:0});
    if(provider){
      if(store){
        store.commit("setMetamaskInstalled", true);
      }
      window.web3 = new Web3(window.ethereum);
      this.web3 = window.web3
      return true;
    } else {
      return false;
    }
  }

  //Изменение адреса кошелька
  accountsChanged($this, accounts) {
    this.wallet.accounts = accounts;
    this.wallet.address = accounts[0];
    if (accounts.length == 0) {
      //localStorage.removeItem('nonce');
      this.store.commit("setUserWpAuth", false)
      this.wallet.connected = false;
    } else{
      this.getBallance();
    }

    this.onSetAccount()
    this.store.commit("setUserAddress")
  }

  //Изменение сети
  networkChanged($this, networkId) {
    this.wallet.chainId = networkId;
    this.onSetChain()
  }

  //Подключение кошелька метамаск
  async metamaskConnect() {
    const metamaskEnabled = await this.metamaskEnabled();
    if (!metamaskEnabled) {
      alert("Please install MetaMask to use this dApp!");
      return;
    }
    const acc = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (acc.length) {
      this.typeConnect = "metamask";
      const $this = this;
      const API_URL = 'https://crypto-graveyard.io/wordpress_admin/wp-json/mta-auth'
      let network = await this.web3.eth.getChainId()

      let data = {
        address: acc[0]
      }

      if (!this.loaded) {
        this.wallet.connected = true
      } else {
        if(this.type == "auction"){
         this.wallet.connected = true
        } else {
          animateState(true)
          setTimeout(() => this.wallet.connected = true, 1500)
        }
      }

      // MTM Auth
      if (localStorage.getItem('WEB3_TYPE_CONNECT') == null) {
        await fetch(`${API_URL}/generate-nonce`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(function (result) {
          $this.web3.eth.personal.sign(result.payload.nonce, acc[0], function (err, signature) {
            let loginData = {
              address: acc[0],
              network: network.toString(),
              signature: signature
            }

            fetch(`${API_URL}/login`, {
                method: 'POST',
                body: JSON.stringify(loginData),
                headers: {
                  'Content-Type': 'application/json'
                }
              })
              .then(response => response.json())
              .then(result => {
                console.log(result)
                localStorage.setItem('nonce', result.nonce)
                $this.store.commit("setUserWpAuth", true)
              })
          })
        })
      }

      this.wallet.accounts = acc;
      this.wallet.address = acc[0];

      this.store.commit("setUserAddress")

      this.wallet.chainId = await this.web3.eth.getChainId();

      this.connector = window.ethereum;



      window.ethereum.on('accountsChanged', function (accounts) {
        $this.accountsChanged($this, accounts);
      });
      window.ethereum.on('chainChanged', function (networkId) {
        $this.networkChanged($this, $this.web3.utils.hexToNumber(networkId))
      });

      localStorage.setItem('WEB3_CONNECT_CACHED_PROVIDER', 'Injected');
      localStorage.setItem('WEB3_TYPE_CONNECT', 'metamask');

      this.getBallance();
    } else {
      //localStorage.removeItem('nonce');
      this.store.commit("setUserWpAuth", false)
      this.wallet.connected = false
    }
  }

  //Изменить сеть у пользователя
  // async switchEthereumChain(chainId) {
  //   const $this = this;
  //   return await this.connector.request({
  //     method: 'wallet_switchEthereumChain',
  //     params: [{
  //       chainId
  //     }]
  //   }).catch(async (switchError) => {
  //     if(switchError.code == 4902){
  //       await $this.addEthereumChain(chainId)
  //     }
  //   });
  // }

  //Добавить сеть у пользователя
  // async addEthereumChain(chainId){
  //   await this.connector.request({
  //     method: 'wallet_addEthereumChain',
  //     params: [
  //       {
  //         chainId: chainId,
  //         chainName: process.env.VUE_APP_CHAIN_NAME,
  //         nativeCurrency: {
  //           name: 'MATIC',
  //           symbol: 'MATIC',
  //           decimals: 18
  //         },
  //         rpcUrls: [process.env.VUE_APP_RPC_URL],
  //         blockExplorerUrls: [process.env.VUE_APP_EXPLORER_URL],
  //       }
  //     ],
  //   });
  // }

  //Получение баланса пользователя
  async getBallance() {
    const balance = await this.web3.eth.getBalance(this.wallet.address);
    this.wallet.balance = balance
    return balance;
  }

  //Отключение кошелька
  disconnect() {
    const $this = this;
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    localStorage.removeItem('WEB3_TYPE_CONNECT');
    if (window.ethereum) {
      window.ethereum.removeListener('accountsChanged', function (accounts) {
        $this.accountsChanged($this, accounts);
      });
      window.ethereum.removeListener('chainChanged', function (networkId) {
        $this.networkChanged($this, this.web3.utils.hexToNumber(networkId))
      });
    }

    this.wallet = {
      connected: false,
      chainId: null,
      accounts: [],
      address: "",
      fetching: false,
      pendingRequest: false
    }
  }
}