import {createApp} from 'vue';
import App from './App.vue'
import '@/assets/scss/style.scss';
import store from '@/store/index';
import router from '@/router/index';
import VueSplide from '@splidejs/vue-splide';
import VueSocialSharing from 'vue-social-sharing';
import { createHead } from '@vueuse/head'

const head = createHead()

createApp(App).use(store).use(router).use(VueSplide).use(VueSocialSharing).use(head).mount('#app')
