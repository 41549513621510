<template>
  <div class="bid-modal_wrap-container">
    <h2 class="bid-modal_title">Place your bid</h2>
    <p class="bid-modal_text" :class="errorBid ? 'bid-modal_text--error' : ''">
      You must bid at least {{ getMaxUserBid }} ETH
    </p>
    <p
      v-show="errorBid"
      class="bid-modal_text error"
      :class="errorBid ? ' active bid-modal_text--error' : ''"
    >
      Error! Check last bid
    </p>
    <label class="bid-modal-field">
      <input
        type="number"
        placeholder="0"
        @input="inputBid"
        v-model="currentBid"
      />
      <span class="currency">ETH</span>
    </label>
    <p
      class="bid-modal_text bid-modal_text--small bid-modal_text--balance"
      :class="getBalance <= currentBid ? 'bid-modal_text--error' : ''"
    >
      Your Balance {{ getBalance }} ETH
    </p>
    <BalanceError v-if="getBalance <= currentBid" />
    <p class="bid-modal_text bid-modal_text--small bid-modal_text--gray">
      Once a bid is placed, it cannot be withdrawn.
    </p>
    <!-- <a href="" class="bid-modal_text bid-modal_text--small bid-modal_text--gray"
      >Learn how our auctions work.</a
    > -->
    <button
      class="button-bid button-bid--green bid-modal_button"
      :disabled="errorBid || currentBid == null || getBalance < currentBid || $store.getters.getBidStatus"
      @click="bid"
    >
      Place bid
    </button>
    <NetworkError v-if="getNetworkConection == 'bad'" />
  </div>
</template>

<script>
import NetworkError from "@/components/parts/error/auction/NetworkConection.vue";
import BalanceError from "@/components/parts/error/auction/BalanceError.vue";
export default {
  name: "BidInfo",
  components: {
    NetworkError,
    BalanceError,
  },
  props: {
    step: Number,
    currentNFT: Object
  },
  data() {
    return {
      currentBid: null,
      errorBid: false,
    };
  },



  computed: {
    getNetworkConection() {
      return this.$store.getters.getNetworkConection;
    },
    getMaxUserBid() { 
      // let maxBid = this.$store.getters.getMaxUserBid;
      let maxBidWithStep = this.currentNFT.price + this.step
      return maxBidWithStep.toFixed(6);
    },
    getBalance() {
      return (this.$store.getters.getAuctionWallet.wallet.balance / Math.pow(10, 18)).toFixed(2);
    },
  },
  methods: {
    inputBid() {
      let check = Number(this.currentBid) >= this.getMaxUserBid;
      if (check) {
        this.errorBid = false;
      } else {
        this.errorBid = true;
      }
    },
    bid() {
      this.$store.getters.getAuctionContract.bid(this.currentNFT.id, Number(this.currentBid))
      this.$store.commit("getAuctionsInfo", true);
      this.$store.commit("setBidStatus", true);
    }
  },
};
</script>

<style lang="scss">
.bid-modal_title {
  font-size: 3rem;
  color: $black;
  margin-bottom: 4rem;
  &--not-conected {
      margin-top: 6rem;
      margin-bottom: 1.5rem;
  }
  &--signature {
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-bottom: 2rem;
  }
}
.bid-modal_wrap-container {
  margin-right: 20rem;
  &--not-conected {
      position: relative;
      margin-top: 3rem;
      &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 6rem;
          height: 6rem;
          background: url(@/assets/img/icons/warning.svg) center center no-repeat;
          background-size: contain;
      }
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-right: 0rem;
    margin-top: 3rem;
  }
}
.bid-modal_text {
  @include noramlTextAuc;
  font-weight: 700;
  color: $black;
  &--not-conected {
      max-width: 28rem;
  }
  &--balance {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
  &--small {
    @include bodyTextAuction;
  }
  &--gray {
    opacity: 0.5;
    margin-top: 0.5rem;
    display: block;
  }
  &--error {
    color: $red;
  }
  &.error {
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}
.bid-modal_button {
  width: 100%;
  margin-top: 2.5rem;
  display: inline-block;
  &:disabled {
    pointer-events: none;
    background-color: $gray;
  }
}
.bid-modal-field {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  input {
    border: 1px solid #d3d3d3;
    border-radius: 0;
    padding: 0.5rem 0.6rem;
    @include h1Auc;
    max-width: 20rem;
    font-weight: 500;
    color: $gray;
    outline: none;
    &:focus {
      color: $black;
      &::placeholder {
        opacity: 0;
        transition: 0.15s ease-in-out;
      }
    }
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    input {
      max-width: 15rem;
    }
    
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}
.currency {
  font-size: 1.95rem;
  color: $white;
  background-color: $black;
  padding: 1.1rem 2rem 1.1rem 1.1rem;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.7rem;
    transform: translateY(-50%);
    background: url(@/assets/img/icons/eth.svg) center center no-repeat;
    background-size: contain;
    width: 1rem;
    height: 1.5rem;
  }
}
</style>