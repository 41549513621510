<template>
  <ul class="socials-list">
    <li>
      <a :href="main ? 'https://opensea.io/collection/cryptograveyardd' : 'https://opensea.io/collection/twelveapes'" class="socials-list_item socials-list_item--opensea"></a>
    </li>
    <li>
      <a href="https://twitter.com/mashkow1?s=21&t=lj0HigR5254n0uaf54khNQ" class="socials-list_item socials-list_item--twitter"></a>
    </li>
    <li>
      <a href="https://discord.gg/qkYMrNjkmJ" class="socials-list_item socials-list_item--dis"></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SocialsList",
  props: [
    'main'
  ]
};
</script>

<style lang="scss">
.socials-list {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  pointer-events: all;
  &--mobile {
    margin-top: 6rem;
  }
  li {
    list-style: none;
    &:not(:first-of-type) {
      margin-left: 1rem;
    }
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    margin-top: 2rem;
  }
}

.socials-list_item {
  list-style: none;
  width: 3rem;
  height: 3rem;
  display: block;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    width: 3rem;
    height: 4rem;
  }
  &--dis {
    background: url(@/assets/img/icons/Discord.svg) center center no-repeat;
    background-size: contain;
  }
  &--inst {
    background: url(@/assets/img/icons/Instagram.svg) center center no-repeat;
    background-size: contain;
  }
  &--opensea {
    background: url(@/assets/img/icons/opensea.svg) center center no-repeat;
    background-size: contain;
  }
  &--telega {
    background: url(@/assets/img/icons/Telegram.svg) center center no-repeat;
    background-size: contain;
  }
  &--twitter {
    background: url(@/assets/img/icons/Twitter.svg) center center no-repeat;
    background-size: contain;
  }
}
</style>