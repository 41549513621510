<template>
  <div class="table-loop">
    <div v-for="item of arrayNft" :key="item.id" class="table-loop_item">
      <div class="table-loop_wrap-mobile">
        <div class="table-loop_item-image">
          <img :src="item.path" :alt="item.name"/>
        </div>
        <p class="title-nft">{{ item.name }}</p>
      </div>
      <div class="table-loop_item-desc">
        <div class="table-loop_item-desc-column">
          <p class="price-nft">{{ item.price }} ETH</p>
          <div class="table-loop_item-desc-wrap">
            <AuctionStatus :endTime="item.end_time"/>
            <p class="time"><TimerAuc :endTime="item.end_time"/></p>
          </div>
        </div>
        <div class="table-loop_item-desc-column">
          <button type="button" class="button-bid" @click="openBidModal(item)" :disabled="item.end_time < currentTime && item.end_time !== 0">Place bid</button>
          <a href="#" @click.prevent="pushToSinglePage(item.id)" class="table-loop_link"
            >open</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuctionStatus from "@/components/parts/auction/AuctionStatus.vue";
import TimerAuc from "@/components/parts/auction/TimerAuc.vue"
export default {
  name: "TableLoopMobile",
  components: {
    TimerAuc,
    AuctionStatus
  },
  computed: {
    arrayNft() {
      return this.$store.getters.getNft;
    },
    currentTime() {
      let currentTime = new Date().getTime() / 1000;
      return currentTime.toFixed(0);
    },
  },
  methods: {
    pushToSinglePage(id) {
      this.$emit("pushToSinglePage", id)
    },
    openBidModal(id) {
      this.$emit("openBidModal", id)
    },
  },
};
</script>