<template>
  <div class="main-header_auc-user-meta">
    <div class="meta meta-wallet">
      <p class="meta-title">Your wallet</p>
      <span
        class="meta-avatar"
        :style="`background: linear-gradient(${userAvatar.colors[0]}, ${userAvatar.colors[1]});`"
      ></span>
    </div>
    <div class="meta meta-balance">
      <div class="meta-balance_container">
        <p class="meta-balance_text">Balance</p>
        <div class="meta-id">
          <span
            class="meta-id_icon"
            :style="`background: linear-gradient(${userAvatar.colors[0]}, ${userAvatar.colors[1]});`"
          ></span>
          <p>{{ maskID }}</p>
        </div>
      </div>
      <p class="meta-balance_value">
        {{ walletBallance }}
        ETH
      </p>
    </div>
    <button
      class="meta main-header_auc-button"
      @click="$store.getters.getAuctionWallet.disconnect()"
    >
      Disconnect
    </button>
  </div>
</template>

<script>
import colors from "@/static/colors.json";
import { maskedFunc } from "@/components/common/masked";
export default {
  data() {
    return {
      maskedFunc,
    };
  },
  computed: {
    maskID() {
      let maskStr = maskedFunc(
        this.$store.getters.getAuctionWallet.wallet.address
      );
      return maskStr;
    },
    userAvatar() {
      return this.$store.getters.getUserAvatar;
    },
    walletAddress() {
      return this.$store.getters.getAuctionWallet.wallet.address;
    },
    walletBallance() {
      let ballance =
        this.$store.getters.getAuctionWallet.wallet.balance !== undefined
          ? this.$store.getters.getAuctionWallet.wallet.balance
          : 0.0;
      // console.log(ballance);
      return (ballance / Math.pow(10, 18)).toFixed(2);
    },
  },
  methods: {
    setUserAvatar() {
      let arrayString = this.walletAddress.split("");
      let indexColor = Number(arrayString[2] + arrayString[3]);
      indexColor = isNaN(indexColor) ? Math.random().toFixed(0) : indexColor
      this.$store.commit("setUserAvatar", colors[indexColor]);
    },
  },
  mounted() {
    if (this.walletAddress !== null && this.walletAddress !== "") {
      this.setUserAvatar();
    }
  },
};
</script>

<style lang="scss">
.meta {
  background-color: $pink;
  border-radius: 10px;
  padding: 0.625rem;
  color: $white;
  border: 2px solid $dark;
  display: block;
  &:not(:first-of-type) {
    margin-top: 0.25rem;
  }
}
.meta-wallet {
  display: flex;
  font-family: "Creepster", sans-serif;
  align-items: center;
  justify-content: space-between;
}
.main-header_auc-user-meta {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    position: static;
    top: 0;
    left: 0;
  }
}

.meta-avatar {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #41e119;
  border-radius: 100%;
  border: 1px solid $black;
}

.meta-title {
  margin-right: 2.5rem;
  font-size: 2.25rem;
}

.meta-balance_value {
  font-size: 3.75rem;
}

.meta-balance_text,
.main-header_auc-button {
  @include noramlTextAuc;
  margin-right: 1rem;
}
.main-header_auc-button {
  margin-top: 0.25rem;
  width: 100%;
  text-align: start;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(@/assets/img/icons/disconect.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}
.meta-balance_container {
  display: flex;
  align-items: center;
}

.meta-id_icon {
  width: 1rem;
  height: 1rem;
  background-color: #41e119;
  display: block;
  border-radius: 100%;
  margin-right: 0.8rem;
}

.meta-id {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
  border: 1px solid #222222;
  border-radius: 60px;
  @include smallText;
}
</style>