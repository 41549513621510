<template>
  <div class="app-graveyard" :class="getStatusModal == 'open' ? 'active' : ''">
    <AppModal
      :paymentStatus="paymentStatus"
      :paymentError="paymentError"
      :ballanceError="ballanceError"
      :transaction="transaction"
      :checkLogin="checkLogin"
      :noInternet="isNotInternet"
      :statusError="projectStatus == 'whitelist' && userGroup == 'public'"
      v-if="$store.getters.getWallet.wallet.connected !== null"
    >
      <!-- <transition name="slide" mode="out-in" appear> -->

      <NetworkError
        v-if="
          isNotInternet ||
          (projectStatus == 'whitelist' && userGroup == 'public')
        "
        :noInternet="isNotInternet"
        :statusError="projectStatus == 'whitelist' && userGroup == 'public'"
      />

      <LoginMetaMask v-else-if="!checkLogin" />

      <Payment
        v-else-if="paymentStatus == 'waiting' || paymentStatus == 'payment'"
        :paymentStatus="paymentStatus"
        :paymentError="paymentError"
        :ballanceError="ballanceError"
        :transaction="transaction"
      />

      <Success
        v-else-if="paymentStatus == 'success'"
        :transaction="transaction"
      />

      <MintingNft v-else :projectStatus="projectStatus" @mint="mint" />

      <!-- </transition> -->
    </AppModal>
  </div>
</template>

<script>
import AppModal from "@/components/modals/AppModal.vue";
import LoginMetaMask from "@/components/parts/LoginMetaMask.vue";
import MintingNft from "@/components/parts/MintingNft.vue";
import Payment from "@/components/parts/Payment.vue";
import Success from "@/components/parts/SuccessStatus.vue";
import NetworkError from "@/components/parts/NetworkError.vue";
import { animateState } from "@/components/common/animation-states";
export default {
  name: "AppGraveyard",
  data: () => ({
    paymentStatus: "",
    paymentError: "",
    ballanceError: false,
    transaction: "",
    isNotInternet: false,
    userGroup: "",
    projectStatus: "",
    success: false
  }),
  components: {
    AppModal,
    LoginMetaMask,
    MintingNft,
    Payment,
    Success,
    NetworkError,
  },
  methods: {
    async mint(count) {
      const $this = this;

      const price = await this.$store.getters.getContract.getPriceForAmount(
        count
      );
      const ballance = await this.$store.getters.getWallet.getBallance();

      if (ballance < price * Math.pow(10, 18)) {
        animateState(true);
        setTimeout(() => {
          $this.paymentStatus = "payment";
          $this.ballanceError = true;
        }, 1500);
      } else {
        const contract = this.$store.getters.getContract;
        contract.mint(count);
        animateState(true);
        setTimeout(() => (this.paymentStatus = "waiting"), 1500);
      }
    },
    updateConnectionStatus() {
      // console.log(navigator.onLine)
      this.isNotInternet = !navigator.onLine;
    },
    getProjectStatus() {
      this.projectStatus = this.$store.getters.getContract.projectStatus;
    },
    getUserGroup() {
      this.userGroup = this.$store.getters.getContract.userStatus;
    },
  },
  computed: {
    checkLogin() {
      return this.$store.getters.getWallet.wallet.connected;
    },
    getStatusModal() {
      return this.$store.getters.getStatusModal;
    },
  },
  watch: {
    "$store.getters.getContract.projectStatus"() {
      this.getProjectStatus();
    },
    "$store.getters.getContract.userStatus"() {
      this.getUserGroup();
    },
    "navigator.onLine"() {
      this.isNotInternet = !navigator.onLine;
    },
  },
  mounted() {
    const $this = this;
    this.getProjectStatus();
    this.getUserGroup();

    this.$store._state.data.blockchain.contract.onErrorBallance = function () {
      animateState(true);
      setTimeout(() => ($this.ballanceError = true), 1500);
    };
    this.$store._state.data.blockchain.contract.onError = function (error) {
      animateState(true);
      setTimeout(() => ($this.paymentError = error), 1500);
    };
    this.$store._state.data.blockchain.contract.onSetTransaction = function (
      transaction
    ) {
      //Переход на ожидание оплаты
      $this.transaction = transaction;
      animateState(true);
      setTimeout(() => ($this.paymentStatus = "payment"), 2000);
    };

    if (!this.success) {
      this.$store._state.data.blockchain.contract.onSuccess = function () {
        animateState(true);
        setTimeout(() => ($this.paymentStatus = "success"), 1500);
        this.success = true

        let data = {
          tokens: this.$store.getters.getWallet.wallet.address,
          contract: this.$store.getters.getContract.contract_address,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-WP-Nonce": localStorage.getItem("nonce"),
          },
          body: JSON.stringify(data),
        };
        let myUrl =
          "https://crypto-graveyard.io/wordpress_admin/wp-json/wp/v2/set_access";
        fetch(myUrl, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          });
      };
    }


    this.$store._state.data.blockchain.wallet.onSetAccount = function () {
      //Изменение аккаунта metamask
      $this.transaction = "";
      $this.paymentStatus = "";
      $this.paymentError = "";
      $this.ballanceError = false;
    };

    this.$store._state.data.blockchain.wallet.onSetChain = function () {
      //Изменение сети metamask
      $this.transaction = "";
      $this.paymentStatus = "";
      $this.paymentError = "";
      $this.ballanceError = false;
    };

    window.addEventListener("online", this.updateConnectionStatus);
    window.addEventListener("offline", this.updateConnectionStatus);
  },

  beforeUnmount() {
    window.removeEventListener("online", this.updateConnectionStatus);
    window.removeEventListener("offline", this.updateConnectionStatus);
  },
};
</script>

<style lang="scss" scoped>
#graveyard {
  z-index: 0;
}

.app-graveyard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  @media only screen and (min-width: 300px) and (max-width: 1200px) {
    align-items: unset;
    justify-content: unset;
    display: block;
  }
}

@media only screen and (min-width: 300px) and (max-width: 1200px) {
  // .front-page {
  //   height: 100vh;
  // }
}
</style>