<template>
    <div class="bid-modal_wrap-container bid-modal_wrap-container--not-conected">
        <h2 class="bid-modal_title bid-modal_title--not-conected">Connect your wallet</h2>
        <p class="bid-modal_text bid-modal_text--not-conected">Please connect your wallet to continue with the transaction.</p>
        <button
          class="button-bid button-bid--black bid-modal_button"
          :style="!$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
          @click="loginMetamask"
        >
            Connect wallet
        </button>
        <a
          target="_blank"
          :href="'https://metamask.app.link/dapp/' + href"
          :style="$store.getters.getMetamaskInstalled ? { display: 'none' } : {}"
          class="button-bid button-bid--black bid-modal_button"
        >
            Connect wallet
        </a>

    </div>
</template>

<script>
export default {
    name: "BidStop",
    data: () => ({
        href: "",
    }),
    methods: {
        getHref() {
            this.href = window.location.href;
        },
        loginMetamask() {
            this.$store.getters.getAuctionWallet.metamaskConnect();
        }
    },
    mounted() {
        this.getHref();
    }
}
</script>

<style lang="scss" scoped>
    .bid-modal_button {
        display: inline-block;
        text-align: center;
    }
</style>