import Wallet from "@/helpers/Wallet.js"
import Contract from "@/helpers/Contract.js"

export default {
    state: {
        auctionWallet: null,
        auctionContract: null,
        NFT: [
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/1.jpg',
                path:  require('@/assets/img/auc-nft/Eminem.jpg'),
                name: "#1",
                price: 0.1,
                id: 1,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/2.jpg',
                path:  require('@/assets/img/auc-nft/Faze_Banks.jpg'),
                name: "#2",
                price: 0.1,
                id: 2,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/3.jpg',
                path:  require('@/assets/img/auc-nft/Gary_Vee.jpg'),
                name: "#3",
                price: 0.1,
                id: 3,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/4.jpg',
                path:  require('@/assets/img/auc-nft/Jimmy_Fallon.jpg'),
                name: "#4",
                price: 0.1,
                id: 4,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/5.jpg',
                path:  require('@/assets/img/auc-nft/Kevin_Hart.jpg'),
                name: "#5",
                price: 0.1,
                id: 5,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
                
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/6.jpg',
                path:  require('@/assets/img/auc-nft/Madonna.jpg'),
                name: "#6",
                price: 0.1,
                id: 6,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/7.jpg',
                path:  require('@/assets/img/auc-nft/Marshmallow.jpg'),
                name: "#7",
                price: 0.1,
                id: 7,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/8.jpg',
                path:  require('@/assets/img/auc-nft/Post_Malone.jpg'),
                name: "#8",
                price: 0.1,
                id: 8,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/9.jpg',
                path:  require('@/assets/img/auc-nft/Snoop_Dogg.jpg'),
                name: "#9",
                price: 0.1,
                id: 9,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/10.jpg',
                path:  require('@/assets/img/auc-nft/Steve_Aoki.jpg'),
                name: "#10",
                price: 0.1,
                id: 10,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/11.jpg',
                path:  require('@/assets/img/auc-nft/Tom_Brady.jpg'),
                name: "#11",
                price: 0.1,
                id: 11,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
            {
                pathOriginal: 'https://graveyard.mypinata.cloud/ipfs/QmZnjDYxVYN3jvgABWeGfHVFDQ37yebvXJwoxBqDUsypRx/12.jpg',
                path:  require('@/assets/img/auc-nft/Travis_Barker.jpg'),
                name: "#12",
                price: 0.1,
                id: 12,
                ended: false,
                bidcount: 0,
                end_time: 0,
                step: 0
            },
        ],
    },
    getters: {
        getNft(state) {
            return state.NFT
        },
        getAuctionWallet(state) {
            return state.auctionWallet
        },
        getAuctionContract(state) {
            return state.auctionContract
        },
        getAuctionContractAbi(state) {
            return state.auctionContractAbi
        }
    },
    mutations: {
        async getAuctionsInfo(state){
            const nfts = state.NFT
            for (var i = 0; i < nfts.length; i++) {
              const auction = await state.auctionContract.getAuction(nfts[i].id)

              nfts[i].price = auction.current_price / Math.pow(10, 18)
              nfts[i].bidcount = auction.bidcount
              nfts[i].end_time = parseInt(auction.end_time)
              nfts[i].step = auction.step / Math.pow(10, 18)
              nfts[i].ended = auction.ended
            }
            state.NFT = nfts
        },
        createAuctionWallet(state) {
            state.auctionWallet = new Wallet("auction");
            state.auctionWallet.metamaskEnabled(this);
            state.auctionWallet.checkConnect(this);
        },
        createAuctionContract(state) {
            state.auctionContract = new Contract("auction");
            state.auctionContract.init(process.env.VUE_APP_CONTRACT_ADDRESS_AUCTION, this);
        },
    }
}