<template>
    <div class="network-error_container">
      <p class="network-error_container-text network-error_container-text--bold">
        No Internet!
      </p>
      <p class="network-error_container-text">
        Poor network connection detected. Please check your connectivity.
      </p>
      <button @click="reload" class="network-error_container-button button">Try Again</button>
    </div>
</template>
<script>
export default {
    name: "ErrorConection",
    methods: {
      reload() {
        window.location.reload();
      }
    }
}
</script>