<template>
     <div class="network-error_container">
      <p class="network-error_container-text network-error_container-text--bold">
        You don't have a whitelist
      </p>
      <p class="network-error_container-text">
        If this is an error, write to the admin at <a href="https://discord.gg/qkYMrNjkmJ">discord</a>
      </p>
      <button @click="reload" class="network-error_container-button button">Try Again</button>
    </div>
</template>
<script>
export default {
    name: "ErrorMintingStatus",
    methods: {
      reload() {
        this.$store.commit("createContract", true);
      }
    }
}
</script>